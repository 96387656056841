import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const ImageContent = (props) => {
  const { image, images, imageArray, isOdd, floorplan, darkButtons } = props;

  const [activeImage, setActiveImage] = useState(0);

  const handleClick = (i) => {
    setActiveImage(i);
  };

  return (
    <Root isOdd={isOdd} floorplan={floorplan}>
      <Image floorplan={floorplan}>
        {imageArray ? (
          imageArray.map((item, index) => (
            <img
              src={images[index].src}
              alt={item.text}
              key={index}
              style={{
                opacity: activeImage === index || index === 0 ? 1 : 0,
                pointerEvents: activeImage === index ? 'all' : 'none',
              }}
            />
          ))
        ) : (
          <img src={image} alt="" />
        )}
      </Image>

      {imageArray && (
        <ButtonSection>
          {imageArray.map((item, index) => (
            <Button
              key={index}
              active={index === activeImage ? 'true' : 'false'}
              onClick={() => handleClick(index)}
              dark={darkButtons}
            >
              {item.text}
            </Button>
          ))}
        </ButtonSection>
      )}
    </Root>
  );
};

ImageContent.propTypes = {
  imageArray: PropTypes.array,
  isOdd: PropTypes.bool,
};

const Root = styled.div`
  position: relative;
  ${(props) =>
    props.floorplan ? vw('width', 375, 688, 1044) : vw('width', 375, 422, 1044)}
  ${(props) =>
    props.floorplan
      ? vw('height', 229.84, 480, 644)
      : vw('height', 229.84, 260, 644)}

  ${(props) =>
    props.isOdd === true
      ? vw('margin-left', 0, 0, 60)
      : vw('margin-right', 0, 0, 60)}

  ${vw('margin-bottom', 90, 0, 0)}
`;

const Image = styled.div`
  ${(props) =>
    props.floorplan ? vw('width', 375, 688, 1044) : vw('width', 375, 422, 1044)}
  ${(props) =>
    props.floorplan
      ? vw('height', 229.84, 480, 644)
      : vw('height', 229.84, 260, 644)};
  ${vw('border-radius', 27, 57, 57)}
  overflow :hidden;
  /* transition: 0.4s ease; */
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s ease;
    position: absolute;
    /* ${vw('border-radius', 27, 57, 57)} */
    transform: scale(1.08);

    top: 0;
    left: 0;
  }
  @media ${media.tablet} {
    /* filter: drop-shadow(0px 20px 54px rgba(0, 0, 0, 0.5)); */
  }
`;

const ButtonSection = styled.section`
  z-index: 500;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  position: relative;
  ${vw('bottom', -24, 24, 24)}
  ${vw('gap', 0, 0, 40)}
  right: 0;
  left: 0;

  @media ${media.tablet} {
    position: absolute;
  }
  @media ${media.desktop} {
    position: absolute;
  }
`;

const Button = styled.button`
  font-weight: 500;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.active === 'true' ? props.theme.color.purple : 'transparent'};
  color: ${(props) =>
    props.dark && props.active === 'false'
      ? props.theme.color.purple
      : props.theme.color.white};

  border: 1px solid ${(props) => props.theme.color.purple};
  ${vw('font-size', 14)}
  ${vw('line-height', 17.09)}
  ${vw('letter-spacing', 3)}

  ${vw('padding-right', 20, 29, 29)}
  ${vw('padding-left', 20, 29, 29)}
  ${vw('padding-top', 11, 11, 17)}
  ${vw('padding-bottom', 12, 12, 16)}
  ${vw('margin-right', 20, 40, 0)}
  ${vw('border-radius', 6, 6, 6)}
  &:last-of-type {
    margin-right: 0;
  }
  @media ${media.desktop} {
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      background-color: ${({ theme }) => theme.color.purple};
      color: white;
    }
  }
`;

export default ImageContent;
