import React from 'react';
import Intl from 'src/components/Intl';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import otherImages from 'src/data/otherImages';
import social from 'src/data/social';

function Footer() {
  return (
    <Intl>
      {(t) => {
        return (
          <Root>
            <UpRow>
              <Grid>
                <SalesGallery>
                  <h4>{t.footer.sections.sales.title}</h4>
                  <p>{t.footer.sections.sales.text}</p>
                </SalesGallery>
                <Contact>
                  <h4>{t.footer.sections.contact.title}</h4>
                  <div>
                    <a
                      href="tel:+85221569111"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      P: 852 21569111
                    </a>
                    <a
                      href="mailto:hksales@gryphonliving.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      E: hksales@gryphonliving.com
                    </a>
                  </div>
                </Contact>
                <Social>
                  <h4>{t.footer.sections.social.title}</h4>
                  <div>
                    {social.map((item, index) => (
                      <a
                        href={item.url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={item.icon} alt={item.name} />
                      </a>
                    ))}
                  </div>
                </Social>
                <LogosWrapper>
                  <img src={otherImages.footer.logos.gryphon} alt="gryphon" />
                </LogosWrapper>
              </Grid>
            </UpRow>
            <span>{t.footer.disclaimer}</span>
          </Root>
        );
      }}
    </Intl>
  );
}

const Root = styled.footer`
  background-color: #1e1a47;

  ${vw('padding-top', 43, 52, 32)}
  ${vw('padding-bottom', 63, 23)}
  ${vw('padding-left', 16, 21, 60)}
  ${vw('padding-right', 16, 21, 60)}

  h4 {
    font-family: 'Helvetica Neue';
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.color.white};

    ${vw('font-size', 12)}
    ${vw('line-height', 24)}
    ${vw('letter-spacing', 2)}
    ${vw('margin-bottom', 2)}
  }

  p,
  a {
    font-family: 'Helvetica Neue';
    font-weight: 400;
    color: ${(props) => props.theme.color.white};

    ${vw('font-size', 12)}
    ${vw('line-height', 16)}
    ${vw('letter-spacing', 2)}
  }

  > span {
    font-family: 'Helvetica Neue';
    font-weight: 400;
    color: ${(props) => props.theme.color.selago};

    ${vw('font-size', 11)}
    ${vw('line-height', 13.2)}
    ${vw('letter-spacing', 1)}
  }
`;

const UpRow = styled.div`
  display: flex;
  ${vw('padding-bottom', 30, 30, 32)}
  @media ${media.tablet} {
    align-items: start;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  justify-items: flex-start;
  align-items: flex-end;

  ${vw('grid-column-gap', 50, 50, 30)}
  ${vw('grid-row-gap', 30, 30, 0)}

  @media ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: start;
    align-items: start;
  }
`;

const SalesGallery = styled.div`
  p {
    ${vw('height', 42)}
  }
`;

const Contact = styled.div`
  > div {
    ${vw('height', 42)}

    a {
      display: block;
    }
  }
`;

const Social = styled.div`
  > div {
    display: flex;
    align-items: flex-end;
    ${vw('height', 36, 42)}

    > * {
      &:not(:first-child) {
        ${vw('margin-left', 19)}
      }
    }
  }
`;

const LogosWrapper = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */

  @media ${media.tablet} {
    width: unset;
  }
  @media ${media.desktop} {
    justify-self: right;
  }
`;

export default Footer;
