module.exports = {
  defaultLocale: 'tc',
  locales: ['en', 'tc'],
  dbProject: 'Gryphon Nova',
  prerender: true,
  analytics: {
    gtag: '',
    gtmId: 'GTM-NGTPSCM',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Gryphon Nova - The Art of Innovation',
  },
  stageHeight: {
    mobile: 568,
    tablet: 1024,
    desktop: 640,
  },
  stage: {
    mobile: 375,
    tablet: 768,
    desktop: 1760,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
