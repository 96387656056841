import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const LineLink = (props) => {
  const { href, text, heroLarge } = props;
  return (
    <Root
      heroLarge={heroLarge}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {text}
      <span />
    </Root>
  );
};

LineLink.propTypes = {
  href: PropTypes.string,
  heroLarge: PropTypes.bool,
};

const Root = styled.a`
  font-weight: 500;
  position: relative;
  border: 1px solid ${(props) => props.theme.color.purpleLight};
  color: ${(props) => props.theme.color.purpleLight};
  text-transform: uppercase;
  ${vw('font-size', 14, 14, 14)}
  ${vw('line-height', 17.09, 17.09, 17.09)}
  ${vw('letter-spacing', 3, 3, 3)}

  ${vw('padding-right', 22, 22, 44)}
  ${vw('padding-left', 22, 22, 44)}
  ${vw('padding-top', 10, 9, 17)}
  ${vw('padding-bottom', 9, 8, 16)}
  ${vw('margin-top', 30, 0)}

  ${vw('bottom', 'auto', 80, 80)}
  ${vw('border-radius', 6)}
  @media ${media.tablet} {
    position: absolute;
  }
  @media ${media.desktop} {
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      color: ${({ theme }) => theme.color.purpleDark};
      background-color: ${({ theme }) => theme.color.purpleLight};
    }
  }
`;

export default LineLink;
