import React from 'react';
import Intl from 'src/components/Intl';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Checkbox = () => {
  return (
    <Intl>
      {(t) => {
        return (
          <Root>
            <input type="checkbox" name="checkbox" id="checkbox" />
            <span />
            <label htmlFor="checkbox" className="footnote dark">
              {t.form.disclaimer}
            </label>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
  text-align: left;
  cursor: pointer;

  ${vw('width', 282, 470)}
  ${vw('margin-top', 40, 30)}
  ${vw('margin-right', 0, 0)}
  ${vw('margin-bottom', 40, 40, 50)}
  ${vw('margin-left', 0, 0)}
  ${vw('padding-left', 24, 24, 36)}

  input,
  span {
    position: absolute;
    top: 0;
    left: 0;
    ${vw('width', 16, 24)}
    ${vw('height', 16, 24)}
  }
  span {
    pointer-events: none;
    border: 1px solid #ffffff;
    cursor: pointer;
    &::after {
      content: '';
      background-color: #ffffff;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${vw('width', 8, 12)}
      ${vw('height', 8, 12)}
    }
  }
  input {
    opacity: 0;
  }
  input:checked + span::after {
    opacity: 1;
  }
  label {
    font-family: 'Founders Grotesk';
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: left;
    cursor: pointer;

    ${vw('font-size', 10, 12, 12)}
    ${vw('line-height', 14, 16.8, 16.8)}
    ${vw('letter-spacing', 1, 1, 1)}
  }
  @media ${media.tablet} {
    grid-column: 1 / 3;
  }
  @media ${media.desktop} {
    input,
    span {
      cursor: pointer;
    }
  }
`;

export default Checkbox;
