import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import LineLink from 'components/typography/LineLink';
import Intl from 'components/Intl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const LargeContent = (props) => {
  const {
    hero,
    home,
    svg,
    subtitle,
    title,
    paragraph,
    image,
    link,
    button,
    svgTitle,
    img,
    className,
  } = props;

  const [hovered, setHovered] = useState('false');

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root hero={hero} home={home}>
          <Home home={home}>
            <Image
              src={image}
              url={img}
              hero={hero}
              isHovered={hovered === 'true' ? 'true' : 'false'}
            />

            {subtitle && <Subtitle>{subtitle}</Subtitle>}

            <Wrapper hero={hero}>
              {svg && <Svg src={svg} />}
              {title && <Title className={className}>{title}</Title>}
              {svgTitle && <SvgTitle className={className} src={svgTitle} />}
              {paragraph && (
                <Paragraph className={className}>{paragraph}</Paragraph>
              )}
              {!hero && button && (
                <Button
                  to={`${getLocaleURL()}${button.target}`}
                  hero={hero}
                  onMouseEnter={() => setHovered('true')}
                  onMouseLeave={() => setHovered('false')}
                  className={className}
                >
                  {button.text}
                </Button>
              )}
              {!hero && link && (
                <LineLink
                  onMouseEnter={() => setHovered('true')}
                  onMouseLeave={() => setHovered('false')}
                  text={link.text}
                  href={link.url}
                  heroLarge
                  className={className}
                />
              )}
            </Wrapper>
          </Home>
        </Root>
      )}
    </Intl>
  );
};

LargeContent.propTypes = {
  svg: PropTypes.string,
  link: PropTypes.object,
  button: PropTypes.object,
  className: PropTypes.string,
};

const Root = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.home ? '80vh' : '70vh')};
  ${(props) => (props.hero || props.home ? null : vw('margin-bottom', 50, 140))}
  /* ${(props) =>
    props.hero || props.home ? vw('padding-top', 50, 140) : null} */
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.home ? 'flex-start' : 'flex-start')};
  flex-direction: column;
  overflow: hidden;
  ${vw('border-radius', 27, 57, 57)}

  /* ${(props) => props.home && vw('padding-top', 0, 60, 60)} */
  @media ${media.tablet} {
    height: 72vh;
  }
  @media ${media.desktop} {
    height: ${(props) => (props.home ? '80vh' : '80vh')};
  }
`;

const Home = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.home ? '70vh' : '100%')};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.home ? 'center' : 'flex-start')};
  flex-direction: column;
  overflow: hidden;
  align-self: center;
  ${vw('border-radius', 27, 57, 57)}
  ${(props) => props.home && vw('margin-top', 0, 0, 0)}
  @media ${media.tablet} {
    height: ${(props) => (props.home ? '80vh' : '100%')};
  }
  @media ${media.desktop} {
    height: ${(props) => (props.home ? '76vh' : '100%')};
  }
  -webkit-transform: translateZ(0); // for safari compatibility
`;

const Image = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  position: absolute;
  overflow: hidden; // when zoomed, the extra parts won't show.
  transition: 0.5s;
  ${vw('border-radius', 27, 57, 57)}

  width: 100%;
  height: 100%;
  ${(props) =>
    props.hero
      ? null
      : props.isHovered === 'true'
      ? null
      : 'filter: blur(4px);'};
  ${(props) =>
    props.hero ? 'transform: scale(1.08);' : 'transform: scale(1.2);'}
  ${(props) =>
    props.hero
      ? null
      : props.isHovered === 'false'
      ? null
      : 'transform: scale(1);'}
`;

const Button = styled(Link)`
  font-weight: 500;
  position: relative;
  border: 1px solid ${(props) => props.theme.color.purpleLight};
  color: ${(props) => props.theme.color.purpleLight};
  text-transform: uppercase;
  ${vw('font-size', 14, 14, 14)}
  ${vw('line-height', 17.09, 17.09, 17.09)}
  ${vw('letter-spacing', 3, 3, 3)}

  ${vw('padding-right', 22, 22, 44)}
  ${vw('padding-left', 22, 22, 44)}
  ${vw('padding-top', 10, 9, 17)}
  ${vw('padding-bottom', 9, 8, 16)}
  ${vw('margin-top', 30, 0)}

  ${vw('bottom', 'auto', 80, 80)}
  ${vw('border-radius', 6)}
  @media ${media.tablet} {
    position: absolute;
  }
  @media ${media.desktop} {
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      color: ${({ theme }) => theme.color.purpleDark};
      background-color: ${({ theme }) => theme.color.purpleLight};
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* The gap wasnt working on iOS devices so switched to margins on the other elements */
  /* ${vw('gap', 0, 0, 40)} */
`;

const Svg = styled.img`
  ${vw('width', 280, 500, 681)}
  ${vw('margin-bottom', 0, 0, 40)}
`;

const Subtitle = styled.h2`
  font-weight: 500;
  color: ${(props) => props.theme.color.white};
  position: absolute;
  text-transform: uppercase;
  ${vw('top', 30, 30, 60)}
  ${vw('font-size', 12, 12, 12)}
  ${vw('line-height', 24, 24, 24)}
  ${vw('letter-spacing', 6.08, 6.08, 6.08)} // 0.38 em
`;

const Title = styled.h1`
  text-align: center;
  color: ${(props) => props.theme.color.white};
  ${vw('font-size', 22, 38, 48)}
  font-weight: 300;
  ${vw('line-height', 28, 45, 57.6)}
  ${vw('letter-spacing', 2, 2, 2)}
  ${vw('margin-bottom', 0, 0, 40)}
  width:80%;
  &.HomeHero {
    font-weight: 400;
    ${vw('font-size', 16, 20, 24)}
    ${vw('letter-spacing', 4, 4, 5)}
    ${vw('margin-top', 20, 10, -20)}
  }
`;

const Paragraph = styled.p`
  ${vw('width', 300, 500, 686)}
  text-align:center;
  font-weight: 400;
  ${vw('font-size', 13, 14, 16)}
  ${vw('line-height', 15, 20, 24)}
  ${vw('letter-spacing', 0.32, 0.32, 0.32)}
  ${vw('margin-top', 30, 30, 0)}
&.tc, &.sc {
    ${vw('font-size', 13, 14, 18)}
    ${vw('line-height', 18, 20, 32)}
  }
  color: ${(props) => props.theme.color.white};
`;

const SvgTitle = styled.img`
  display: block;
  ${vw('width', 150, 230)}
  ${vw('margin-bottom', 0, 0, 40)}
`;

export default LargeContent;
