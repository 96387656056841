import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const ProgressBar = (props) => {
  const { progress, wholeBookmark, activeBookmark } = props;
  const { playedSeconds } = progress;

  const { start, end } = wholeBookmark;
  const [stillRelevant, setStillRelevant] = useState(false);
  const [subProgress, setSubProgress] = useState(0);
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  useEffect(() => {
    if (playedSeconds > end || playedSeconds < start) {
      setStillRelevant(false);
    }

    if (wholeBookmark === activeBookmark) {
      if (playedSeconds < end && playedSeconds > start) {
        setStillRelevant(true);
        setSubProgress(Math.abs(start - playedSeconds));
        let width = 78;
        let totalDuration = end - start;
        let ratio = width / totalDuration;
        setProgressBarWidth(subProgress * ratio);
      }
    }
    return () => {};
  }, [progress]);

  return (
    <Root stillRelevant={stillRelevant}>
      <Total stillRelevant={stillRelevant}>
        <Progress
          stillRelevant={stillRelevant}
          subProgress={subProgress}
          playedSeconds={playedSeconds}
          start={start}
          progressBarWidth={progressBarWidth}
        />
      </Total>
    </Root>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.object,
  wholeBookmark: PropTypes.object,
  activeBookmark: PropTypes.object,
};

const Root = styled.div`
  display: ${(props) => (props.stillRelevant ? 'block' : 'block')};
  ${vw('margin-top', 6, 7, 13)}
`;
const Total = styled.div`
  background-color: ${(props) => props.theme.color.purpleLight + '50'};
  ${(props) => (props.stillRelevant ? null : 'opacity: 0;')}
  ${vw('width', 78, 78, 78)}
  ${vw('height', 1.5, 1.5, 1.5)}
  ${vw('border-radius', 5, 5, 5)};
`;

const Progress = styled.div`
  background-color: ${(props) =>
    props.stillRelevant
      ? props.theme.color.purpleLight
      : props.theme.color.purpleLight + '50'};
  opacity: ${(props) => (props.stillRelevant ? 1 : 0)};
  ${(props) => vw('width', props.progressBarWidth)};
  ${vw('border-radius', 5, 5, 5)};

  height: 100%;
`;

export default ProgressBar;
