import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Content from 'components/layout/Content';
import Hero from 'components/layout/Hero';
import Separator from 'components/layout/Separator';
import Video from 'components/Video/Video';
import Page from 'components/Page';

import content from 'src/data/content';
import images from 'src/data/residencesImages';

import vw from 'src/styles/utils';

const Residences = () => {
  return (
    <Intl>
      {(t, { locale, getLocaleURL }) => {
        const r = t.residences;
        return (
          <Page>
            <Hero
              {...r.hero}
              hero
              img={require('src/assets/images/residences/Residences-Hero.jpg')}
              className={locale}
            />
            <Space />
            <Content
              reverse={false}
              id={0}
              data={r.content[0]}
              images={images[0].image[locale]}
              floorplan
              multi
              darkButtons
              className={locale}
              getLocaleURL={getLocaleURL}
              link
            />
            <Video url={content.residences.video} />
            {/* <Space /> */}
            <Separator
              className={locale}
              title={r.separator.title}
              paragraph={r.separator.paragraph}
              button={r.separator.button}
              buttonLink="/pdf/FeatureSheet.pdf"
            />
            {r.content.map((item, index) =>
              r.content.indexOf(item) !== 0 ? (
                <Content
                  key={index}
                  reverse={false}
                  id={index - 1}
                  data={item}
                  images={images[index].image}
                  multi
                  floorplan
                  className={locale}
                />
              ) : null
            )}
            <Separator
              button={r.separator.button}
              className={locale}
              buttonLink="/pdf/FeatureSheet.pdf"
            />
          </Page>
        );
      }}
    </Intl>
  );
};

const Space = styled.div`
  ${vw('height', 70, 140, 260)}
`;

export default Residences;
