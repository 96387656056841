import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Content from 'components/layout/Content';
import Hero from 'components/layout/Hero';
import Page from 'components/Page';

import content from 'src/data/content';
import hero from 'src/data/hero';

import vw from 'src/styles/utils';

const Amenities = () => {
  const handleSrc = (locale) => {
    if (locale === 'sc') {
      return require(`src/assets/images/amenities/amenities-main-sc.png`);
    } else if (locale === 'tc') {
      return require(`src/assets/images/amenities/amenities-main-tc.png`);
    } else {
      return require(`src/assets/images/amenities/amenities-main-en.png`);
    }
  };

  return (
    <Intl>
      {(t, { locale }) => {
        const a = t.amenities;
        return (
          <Page>
            <Hero
              {...a.hero}
              img={hero.amenities.image}
              hero
              className={locale}
            />
            <ImageWrapper>
              <img src={handleSrc(locale)} alt="" />
            </ImageWrapper>
            {a.content.map((item, index) => (
              <Content
                className={locale}
                reverse={false}
                id={index}
                img={content.amenities[index]}
                data={item}
              />
            ))}
          </Page>
        );
      }}
    </Intl>
  );
};

const ImageWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  ${vw('margin-bottom', 70, 70, 260)}
  ${vw('margin-top', 70, 70, 260)}
  img {
    width: 100%;
  }
`;

export default Amenities;
