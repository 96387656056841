import React, { useEffect } from 'react';

const RedirectByIP = () => {
  const onSuccess = (result) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    //console.log(params.ip)
    let ipCountry = result.country.iso_code;
    if (params.ip !== 'hk') {
      if (ipCountry.toLowerCase() !== 'hk') {
        // redirect to hk site
        window.location.href = 'https://gryphonnova.com';
      }
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (window.geoip2 && !window.__PRERENDERING) {
      window.geoip2.country(onSuccess, onError);
    }
  }, []);

  return <></>;
};

export default RedirectByIP;
