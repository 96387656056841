import React from 'react';

import Intl from 'components/Intl';
import Page from 'components/Page';
import GoogleMapBlock from 'components/layout/GoogleMapBlock';
import Hero from 'components/layout/Hero';

const Contact = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Page>
          <Hero
            {...t.contact.hero}
            hero
            img={require('src/assets/images/contact/contact_hero.jpg')}
            className={locale}
          />
          <GoogleMapBlock
            topMargin
            title={t.contact.hk.title}
            phone="+852-2156-9111"
            email="hkinfo@gryphonliving.com"
            address={
              <>
                4/F Lee Garden Three, 1 Sunning Road,
                <br /> Causeway Bay, Hong Kong
              </>
            }
            zoom={14.5}
            center={{
              lat: 22.27801,
              lng: 114.185094,
            }}
            logoCoord={{
              lat: 22.27801,
              lng: 114.185094,
            }}
            directions="Spaces+-+Hong+Kong,+Spaces+Lee+Garden+3/@22.2779997,114.1828896,17z/data=!3m1!4b1!4m5!3m4!1s0x340401207d42d21f:0xc2f5199e2b217103!8m2!3d22.2779997!4d114.1850836"
          />
          <GoogleMapBlock
            title={t.contact.vancouver.title}
            phone="604-620-8295"
            email="sales@gryphonhousevancouver.com"
            address={
              <>
                2762 Granville St, Vancouver
                <br />
                Vancouver, BC
                <br />
                BC V6H 3J3
              </>
            }
            zoom={13}
            center={{
              lat: 49.2611424,
              lng: -123.1405747,
            }}
            logoCoord={{
              lat: 49.2611424,
              lng: -123.1405747,
            }}
            directions="2762+Granville+St,+Vancouver,+BC+V6H+3J3/@49.2611424,-123.1405747,17z/data=!3m1!4b1!4m5!3m4!1s0x548673b8afe0a8dd:0x2466b271058e8987!8m2!3d49.2611424!4d-123.138386"
          />
        </Page>
      )}
    </Intl>
  );
};

export default Contact;
