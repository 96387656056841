import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Background from 'components/Background';

const Page = (props) => {
  const { home, children } = props;

  gsap.registerPlugin(ScrollTrigger);

  const $container = useRef();

  const handleLoad = ($background) => {
    const container = $container.current;
    const background = $background.current;

    const to = -(background.clientHeight - window.innerHeight);

    gsap.to(background, {
      top: to,
      scrollTrigger: {
        trigger: container,
        start: 'top 90%',
        end: 'bottom top',
        scrub: 0.5,
      },
    });
  };

  return (
    <Root ref={$container}>
      <Background handleLoad={handleLoad} home={home} />
      {children}
    </Root>
  );
};

Page.propTypes = {
  home: PropTypes.bool,
};

const Root = styled.main`
  position: relative;
`;

export default Page;
