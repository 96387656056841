import React, { useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

import Intl from 'components/Intl';
import Hero from 'components/layout/Hero';
import Footer from 'components/Footer/Footer';
import Page from 'components/Page';

import content from 'src/data/content';
import hero from 'src/data/hero';

const Home = () => {
  const $fullpage = React.useRef();

  useEffect(() => {
    if ($fullpage.current) {
      $fullpage.current.fullpageApi.reBuild();
    }
  }, []);

  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Page home>
            <ReactFullpage
              //fullpage options
              licenseKey={'C986626D-95ED4BAB-9DB9CB47-833B0B36'}
              scrollingSpeed={1000} /* Options here */
              scrollOverflow={true}
              ref={$fullpage}
              verticalCentered={false}
              render={({ state, fullpageApi }) => (
                <ReactFullpage.Wrapper>
                  <Hero
                    {...t.home.hero}
                    {...hero.home}
                    img={hero.home.image}
                    hero
                    fullpage
                    home
                    className={'HomeHero'}
                  />
                  {t.home.content.map((item, index) => (
                    <div key={index} className="section">
                      <Hero
                        {...item}
                        {...content.home[index]}
                        img={content.home[index].image}
                        key={index}
                        home
                        className={locale}
                      />
                      {index === t.home.content.length - 1 && <Footer />}
                    </div>
                  ))}
                </ReactFullpage.Wrapper>
              )}
            />
            <Footer className={locale} />
          </Page>
        );
      }}
    </Intl>
  );
};

export default Home;
