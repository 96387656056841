export default [
  {
    src: require('src/assets/images/location/location-1.jpg'),
  },
  {
    src: require('src/assets/images/location/location-2.jpg'),
  },
  {
    src: require('src/assets/images/location/location-3.jpg'),
  },
  {
    src: require('src/assets/images/location/location-4.jpg'),
  },
  {
    src: require('src/assets/images/location/location-5.jpg'),
  },
];
