import React, { useEffect, useRef, useState } from 'react';
import Intl from 'src/components/Intl';
import styled from 'styled-components';
// import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import cookie from 'cookie-parse';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import Input from './elements/Input';
import Select from './elements/Select';
import Checkbox from './elements/Checkbox';
import LoadingDots from './elements/LoadingDots';

import vw from 'src/styles/utils';

// import Insight from 'src/plugins/insight';

const Form = (props) => {
  const { className } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  // let siteKey = '6LfloNIZAAAAAJ-xeTdCTK0IocTKKHq4acwpN0kz';
  const cookies = cookie.parse(document.cookie);

  const tracking = useSelector((state) => state.tracking);

  useEffect(() => {
    // loadReCaptcha(siteKey);
    // Hides spam trap
    document.getElementById('are_you_simulated').style.display = 'none';
  }, []);

  const $form = useRef();

  // let submitting = true;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      e.preventDefault();
      return;
    }

    setIsSubmitting(true);

    var form = $form.current;
    var missing = '';
    var required = { contact_email: 'Email' };
    var customRequired = document.querySelectorAll(
      'input:required, textarea:required, select:required'
    );
    var questionsRequired = {
      //agent: 'Are you an agent',
    };
    // Adds custom required inputs to the 'required' object
    for (var i = 0; i < customRequired.length; i++) {
      required[customRequired[i].id] = customRequired[
        i
      ].parentNode.firstElementChild.innerHTML.replace('*', '');
    }
    // Adds required question inputs to the 'required' object
    for (let key in questionsRequired) {
      console.log(questionsRequired);
      required[key] = questionsRequired[key];
    }

    // Iterates through required fields and adds any that have
    // not been populated to 'missing' list
    for (let key in required) {
      var elements = Array.from(
        document.querySelectorAll("[id^='" + key + "']")
      );

      if (elements.length > 0) {
        let missing_field = true;

        elements.forEach(function (el) {
          if (
            el.length < 1 ||
            (el &&
              ((el.type === 'checkbox' && el.checked) ||
                (el.type === 'radio' && el.checked) ||
                (el.type !== 'radio' && el.type !== 'checkbox' && el.value) ||
                (document.getElementById(key + '_other_text') &&
                  document.getElementById(key + '_other_text').value)))
          ) {
            missing_field = false;
          }
        });

        if (missing_field) {
          // missing += '- ' + required[key] + '\r\n';
          missing += ' ';
        }
      }
    }

    // Tests email input value against RFC 5322 Official Standard Email Regex
    var email = document.getElementById('contact_email').value;
    if (
      //eslint-disable-next-line
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      )
    ) {
      // missing += '- Email is invalid\r\n';
      missing += ' ';
    }

    if (missing !== '') {
      // alert('The following fields are incomplete:\r\n' + missing);
      alert('Please fill out the required fields.');
      return false;
    }

    // Prevents duplicate submissions
    // if (isSubmitting) {
    // const queryString = window.location.search;
    // const referrer = document.referrer;
    // let name =
    //   document.getElementById('contact_first_name').value +
    //   ' ' +
    //   document.getElementById('contact_last_name').value;
    // let email = document.getElementById('contact_email').value;
    // let postal = document.getElementById('contact_postcode').value;

    // let pageName = 'New Registrant - ' + postal;

    // await Insight({ name, email, pageName, referrer, queryString });

    const getQueryVariable = (variable) => {
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
        }
      }
      console.log('Query variable %s not found', variable);
    };

    let tkInput = document.createElement('input');
    tkInput.type = 'hidden';
    tkInput.name = 'authenticity_token';
    tkInput.value =
      'GJa5Yh88/3mJRc/JuOBcLhskBZnQL1B4TF/C7wgdi6zfXN6wCjlge4r64SAuDx6Lvox6HlUXh95K46cdukIYPQ==';

    let gidInput = document.createElement('input');
    gidInput.type = 'hidden';
    gidInput.name = 'contact[ga]';
    gidInput.value = cookies._ga;

    let uSource = document.createElement('input');
    uSource.type = 'hidden';
    uSource.name = 'contact[source]';
    if (
      (getQueryVariable('utm_source') === '') &
      (getQueryVariable('gclid') !== '')
    ) {
    } else {
    }
    uSource.value = getQueryVariable('utm_source');

    let ad_tag_id = document.createElement('input');
    ad_tag_id.type = 'hidden';
    ad_tag_id.name = 'answers[10334][answers]';
    ad_tag_id.value = tracking.ad_tag_id;

    let utm_source = document.createElement('input');
    utm_source.type = 'hidden';
    utm_source.name = 'answers[10345][answers]';
    utm_source.value = tracking.utm_source;

    let uMedium = document.createElement('input');
    uMedium.type = 'hidden';
    uMedium.name = 'contact[medium]';
    uMedium.value = tracking.utm_medium;

    let uCampaign = document.createElement('input');
    uCampaign.type = 'hidden';
    uCampaign.name = 'contact[campaign]';
    uCampaign.value = tracking.utm_campaign;

    let uContent = document.createElement('input');
    uContent.type = 'hidden';
    uContent.name = 'contact[content]';
    uContent.value = tracking.utm_content;

    form.appendChild(utm_source);
    form.appendChild(ad_tag_id);
    form.appendChild(tkInput);
    form.appendChild(gidInput);
    form.appendChild(uSource);
    form.appendChild(uMedium);
    form.appendChild(uCampaign);
    form.appendChild(uContent);
    form.action =
      'https://hook.integromat.com/6ptp9irmobpotl9eigu74mg7w9lzigyo';
    form.submit();

    return false;
    // }
    // setIsSubmitting(false);

    // If you are hosting this form on your own site and have reCAPTCHA enabled,
    // ensure that this is populated with your own site key, as well as include
    // the following script before this code:
    //   <script src="https://www.google.com/recaptcha/api.js?render=YOUR_SITE_KEY">
    // For more information, see: https://developers.google.com/recaptcha/docs/v3
    // let siteKey = '6LfloNIZAAAAAJ-xeTdCTK0IocTKKHq4acwpN0kz';
    // window.grecaptcha.ready(function () {
    //   window.grecaptcha
    //     .execute(siteKey, { action: 'registration' })
    //     .then(function(token){
    //       const tokenInput = document.querySelector('.g-recaptcha-response');
    //       if (tokenInput) {
    //         tokenInput.value = token;
    //         form.appendChild(tokenInput);
    //       }

    //       let name =
    //       document.getElementById('contact_first_name').value +
    //       ' ' +
    //       document.getElementById('contact_last_name').value;
    //       let email = document.getElementById('contact_email').value;
    //       let postal = document.getElementById('contact_postcode').value;

    //       let pageName = 'New Registrant - '+ postal;

    //       Insight({name:name,email:email,pageName:pageName });
    //     })
    //     .then(function (token) {
    //       form.submit();
    //     });
    // });
  };

  return (
    <Intl>
      {(t, { locale, getLocaleURL }) => (
        <Root method="post" accept-charset="UTF-8" ref={$form}>
          {/* <ReCaptcha
            sitekey="6LfloNIZAAAAAJ-xeTdCTK0IocTKKHq4acwpN0kz"
            action="registration"
          /> */}
          <input name="utf8" type="hidden" value="✓" />

          {locale === 'tc' ? (
            <input
              id="redirect_success"
              name="redirect_success"
              type="hidden"
              value={window.location.origin + '/thank-you/'}
            />
          ) : (
            <input
              id="redirect_success"
              name="redirect_success"
              type="hidden"
              value={window.location.origin + '/' + locale + '/thank-you/'}
            />
          )}
          <input
            type="hidden"
            name="redirect_error"
            id="redirect_error"
            value={window.location.origin + getLocaleURL() + '/register/'}
          ></input>
          <input
            type="text"
            name="are_you_simulated"
            id="are_you_simulated"
            placeholder="Leave this field blank"
          ></input>
          <input
            type="hidden"
            name="source"
            id="source"
            value="Website HK"
          ></input>
          <Input
            className={className}
            name="contact[first_name]"
            id="contact_first_name"
            type="text"
            label={t.form.firstName}
            required
          />
          <Input
            className={className}
            name="contact[last_name]"
            id="contact_last_name"
            type="text"
            label={t.form.lastName}
            required
          />
          <Input
            className={className}
            name="contact[email]"
            id="contact_email"
            type="email"
            label={t.form.email}
            required
          />
          <Input
            className={className}
            name="contact[phone]"
            id="contact_phone"
            type="tel"
            label={t.form.phone}
            required
          />

          <Select
            className={className}
            name="answers[8531][answers]"
            id="answers_8531"
            label={t.form.howHeard}
            options={t.form.howHeardOptions}
          />
          <Select
            className={className}
            name="answers[9420][answers]"
            id="answers_9420"
            label={t.form.typeHome}
            options={t.form.typeHomeOptions}
          />
          {/* <Select
            name="answers[10355][answers]"
            id="answers_10355"
            label={t.form.typeBudget}
            options={t.form.typeBudgetOptions}
          /> */}

          <Checkbox className={className} />
          <Button
            className={className}
            handleClick={(e) => handleFormSubmit(e)}
            text={t.form.registerButton}
          >
            {isSubmitting ? <LoadingDots /> : t.form.registerButton}
          </Button>
        </Root>
      )}
    </Intl>
  );
};

export default Form;

const Root = styled.form`
  ${vw('max-width', 335, 511, 468)}
  ${vw('padding-bottom', 60, 80)}
  ${vw('padding-top', 10)}
  /* ${vw('margin-left', 0, 0, 82)} */
  ${vw('margin-top', 40, 40, 40)}

  #g-recaptcha {
    display: none;
  }
`;
