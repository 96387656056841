import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgressBar from 'components/Video/elements/ProgressBar';

import vw from 'src/styles/utils';

const Bookmark = (props) => {
  const { wholeBookmark, icon, progress, activeBookmark, setActiveBookmark } =
    props;

  // const [bookmarkDuration, setBookmarkDuration] = useState(end - start);

  const handleClick = () => {
    setActiveBookmark(wholeBookmark);
  };

  return (
    <Root
      activeBookmark={activeBookmark}
      onClick={handleClick}
      wholeBookmark={wholeBookmark}
    >
      <Icon src={icon} alt="" />
      <ProgressBar
        activeBookmark={activeBookmark}
        progress={progress}
        wholeBookmark={wholeBookmark}
      />
    </Root>
  );
};

Bookmark.propTypes = {
  wholeBookmark: PropTypes.object,
  icon: PropTypes.string,
  progress: PropTypes.object,
  activeBookmark: PropTypes.object,
  setActiveBookmark: PropTypes.func,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* transition: 0.4s; */
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  opacity: ${(props) =>
    props.activeBookmark === props.wholeBookmark ? 1 : 0.5};
`;

const Icon = styled.img`
  color: ${(props) => props.theme.color.purpleLight};
  ${vw('margin-bottom', 6, 6, 6)}
`;

export default Bookmark;
