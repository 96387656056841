export default {
  color: {
    purpleDark: '#170432',
    purple: '#A67FB9',
    purpleLight: '#F7F3F9',
    white: '#FFFFFF',
    selago: '#E3DCE4',
  },
  font: {
    en: 'Helvetica Neue',
    sc: 'Noto Sans SC',
    tc: 'Noto Sans TC',
  },
  animation: {
    timingFunction: 'cubic-bezier(.3,.12,.56,.91)',
    time: '0.4s',
  },
};
