import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Roboto';
    width: 100%;
    overflow-x: hidden;
    // This is a temporary fix to disable the iframe coming from react-hot-loader in development
    > iframe {
      display: none;
    }
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    /* text-align: center; */
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
  body{
    background-color: ${theme.color.purpleDark};
  }

  .light {
    color: ${theme.color.purpleLight};
  }

  .white {
    color: white;
  }

  .uppercase {
    text-transform: uppercase;
  }

  h4 {
    font-weight: 500;
    ${vw('font-size', 12, 12, 12)}
    ${vw('line-height', 24, 24, 24)}
    ${vw('letter-spacing', 6.08, 6.08, 6.08)} 
  }

  p {
    font-weight: 400;
    ${vw('font-size', 16, 16, 16)}
    ${vw('line-height', 24, 24, 24)}
    ${vw('letter-spacing', 0.32, 0.32, 0.32)}
    
  }
  .en {
    font-family: ${theme.font.en};
  }
  .sc {
    font-family: ${theme.font.sc};
  }
  .tc {
    font-family: ${theme.font.tc};
  }
`;
