import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import Page from 'components/Page';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const NotFound = () => {
  return (
    <Page>
      <Root>
        <h4 className="light uppercase">404!</h4>
        <p className="light">The page you are looking for does not exist.</p>
        <Button text to="/">
          Return to Homepage
        </Button>
      </Root>
    </Page>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  position: relative;
  p {
    ${vw('margin-top', 30, 30, 40)}
    ${vw('margin-bottom', 30, 30, 40)}
  }
  @media ${media.desktop} {
    height: 90vh;
  }
`;

export default NotFound;
