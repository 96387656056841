import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import TopBar from 'src/components/Header/elements/TopBar';
import LanguageSelector from 'src/components/Header/elements/LanguageSelector';

import menuBackground from 'src/assets/images/menu-background.png';

import vw, { vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Overlay = (props) => {
  const { isMenuActive, setIsMenuActive, data, className } = props;

  const [hovered, setHovered] = useState('false');

  const handleClick = () => {
    setIsMenuActive(!isMenuActive);
  };

  const handleClose = () => {
    setIsMenuActive(false);
  };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root
          img={menuBackground}
          isMenuActive={isMenuActive}
          style={{
            opacity: isMenuActive ? 1 : 0,
            pointerEvents: isMenuActive ? 'all' : 'none',
          }}
        >
          <Background src={menuBackground} />
          <TopBar
            className={className}
            isMenuActive={isMenuActive}
            setIsMenuActive={setIsMenuActive}
            data={data}
          />
          <nav>
            <Wrapper>
              {t.navigation.menu.map((item, index) => (
                <StyledLink
                  className={className}
                  onClick={handleClick}
                  to={`${getLocaleURL()}${item.url}`}
                  key={index}
                  onMouseEnter={() => setHovered('true')}
                  onMouseLeave={() => setHovered('false')}
                  hovered={hovered === 'true' ? 'true' : 'false'}
                >
                  {item.title}
                </StyledLink>
              ))}
              <MobileWrapper>
                <LanguageSelector className={className} />
              </MobileWrapper>
              <LinkItem
                className={className}
                to={`${getLocaleURL()}${data.side[1].url}`}
                onClick={handleClose}
              >
                {data.side[1].title}
              </LinkItem>
            </Wrapper>
          </nav>
        </Root>
      )}
    </Intl>
  );
};

Overlay.propTypes = {
  isMenuActive: PropTypes.bool,
  setIsMenuActive: PropTypes.func,
  className: PropTypes.string,
};

const Root = styled.section`
  z-index: 600;
  position: relative;
  height: 100vh;
  width: 100vw;
  position: fixed;
  transition: 0.4s ease;
  opacity: ${(props) => (props.isMenuActive ? 1 : 0)};
  pointer-events: ${(props) => (props.isMenuActive ? 'all' : 'none')};
  nav {
    z-index: 999;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    ${vw('row-gap', 0, 0, 36)}
    align-items: center;
    justify-content: center;
    ${vw('padding-top', 100, 0, 140)}
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${vw('row-gap', 0, 0, 36)}
  align-items: center;
  justify-content: center;
`;

const Background = styled.div`
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 100vw;
  height: 100vh;
`;

const MobileWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${vw('margin-top', 60, 0, 0)}
  @media ${media.tablet} {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: 0.4s;
  color: ${(props) => props.theme.color.white};
  opacity: ${(props) => (props.hovered === 'true' ? 0.4 : null)};
  font-weight: 200;
  ${vw('font-size', 30, 48, 64)}
  ${vw('line-height', 36, 57.6, 76.8)}
  ${vw('letter-spacing', 2, 2, 2)}
  ${vw('margin-bottom', 20, 30, 0)}
  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 1;
  }
`;

const LinkItem = styled(Link)`
  z-index: 9999;

  color: ${(props) => props.theme.color.white};
  text-transform: uppercase;
  align-self: center;

  font-weight: 500;
  ${vw('font-size', 14, 14, 14)};
  ${vw('line-height', 15.51, 15.51, 17.09)};
  ${vw('letter-spacing', 1, 1, 3)};
  ${vw('margin-top', 60, 0)}
  @media ${media.tablet} {
    position: absolute;
    top: ${`calc(100% + ${vwTablet(120)})`};
  }
  @media ${media.desktop} {
    display: none;
  }
`;

export default Overlay;
