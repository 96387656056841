import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Intl from 'components/Intl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import logo from 'src/assets/images/footer/logos/gryphon-nova-logo.svg';
import LanguageSelector from './LanguageSelector';
import otherImages from 'src/data/otherImages';

const TopBar = (props) => {
  const { data, isMenuActive, setIsMenuActive, className } = props;

  const location = useLocation();

  const { hasPageLoad } = useSelector((state) => state.loader);

  const handleClick = () => {
    setIsMenuActive(!isMenuActive);
  };

  const handleClose = () => {
    setIsMenuActive(false);
  };

  const path = location.pathname.replaceAll('/', '');
  const isHome = path === '' || path === 'sc' || path === 'tc';

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root isHome={isHome} hasPageLoad={hasPageLoad}>
          <Wrapper>
            {isMenuActive ? (
              <MenuButton
                src={otherImages.navigation.close}
                onClick={handleClick}
              />
            ) : (
              <MenuButton
                src={otherImages.navigation.menu}
                onClick={handleClick}
              />
            )}
            <MobileHide>
              <LanguageSelector className={className} />
            </MobileHide>
          </Wrapper>
          <LogoWrapper>
            <LinkLogo
              className={className}
              to={`${getLocaleURL()}/`}
              onClick={handleClose}
            >
              <Logo src={logo} />
            </LinkLogo>
          </LogoWrapper>
          <DesktopWrapper>
            <TextItem
              className={className}
              to={`${getLocaleURL()}${data.side[1].url}`}
              onClick={handleClose}
              desktopOnly
            >
              {data.side[1].title}
            </TextItem>
            <TextItem
              className={className}
              to={`${getLocaleURL()}${data.side[2].url}`}
              onClick={handleClose}
            >
              {data.side[2].title}
            </TextItem>
          </DesktopWrapper>
          <MobileWrapper>
            <TextItem
              className={className}
              to={`${getLocaleURL()}${data.side[2].url}`}
              onClick={handleClose}
            >
              {data.side[2].title}
            </TextItem>
          </MobileWrapper>
        </Root>
      )}
    </Intl>
  );
};

TopBar.propTypes = {
  isMenuActive: PropTypes.bool,
  setIsMenuActive: PropTypes.func,
  className: PropTypes.string,
};

const Root = styled.header`
  position: ${(props) => (props.isHome ? 'fixed' : 'fixed')};
  top: 0;
  z-index: 1000;
  width: 100%;
  ${vw('height', 100, 140, 140)}
  ${vw('padding-right', 20, 30, 70)}
  ${vw('padding-left', 20, 30, 70)}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s ease;
  opacity: ${(props) => (props.hasPageLoad ? 1 : 0)};
  pointer-events: ${(props) => (props.hasPageLoad ? 'all' : 'none')};
  transition-delay: 0.8s;
  background: rgba(10, 2, 42, 0.1);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  p {
    color: ${(props) => props.theme.color.white};
  }
  > * {
    width: 33.33%;
  }
`;

const MenuButton = styled.img`
  width: 40px;
  height: 32px;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  ${vw('gap', 0, 35, 70)}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DesktopWrapper = styled.div`
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-end;
  display: none;
  /* ${vw('gap', 0, 0, 70)} */
  a:first-of-type {
    /* ${vw('margin-right', 20, 22, 70)} */
  }

  @media ${media.tablet} {
    display: flex;
  }
`;
const TextItem = styled(Link)`
  color: ${(props) => props.theme.color.white};
  font-weight: 500;
  text-align: right;
  display: ${(props) => (props.desktopOnly ? 'none' : 'block')};
  ${vw('font-size', 13, 13, 14)};
  ${vw('line-height', 15.51, 15.51, 17.09)};
  ${vw('letter-spacing', 1, 1, 3)};
  ${vw('min-width', 1, 120, 140)};
  &.tc,
  &.sc {
    ${vw('font-size', 13, 13, 16)};
  }
  @media ${media.desktop} {
    display: block;
  }
`;
const LinkLogo = styled(Link)`
  display: flex;
  ${vw('gap', 0, 35, 160)}
  img {
    ${vw('width', 100, 124, 160)}
  }
`;
const Logo = styled.img``;

// const LinkItem = styled.a`
//   color: ${(props) => props.theme.color.white};
//   font-weight: 500;
//   ${vw('font-size', 13, 13, 14)};
//   ${vw('line-height', 15.51, 15.51, 17.09)};
//   ${vw('letter-spacing', 1, 1, 3)};
//   ${vw('min-width', 1, 120, 140)};
//   text-align: right;
//   &.tc,
//   &.sc {
//     ${vw('font-size', 13, 13, 16)};
//   }
// `;

const MobileWrapper = styled.div`
  text-transform: uppercase;

  display: flex;
  justify-content: flex-end;
  gap: 70px;
  @media ${media.tablet} {
    display: none;
  }
`;

const MobileHide = styled.div`
  display: none;
  @media ${media.tablet} {
    display: flex;
  }
`;

export default TopBar;
