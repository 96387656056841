import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vw from 'src/styles/utils';
import LargeContent from 'components/layout/LargeContent';

const Hero = (props) => {
  const { img, fullpage, className } = props;
  return (
    <Root className={`${fullpage && 'section'} ${className}`}>
      <Space />
      <LargeContent {...props} img={img} />
      <Image></Image>
    </Root>
  );
};

Hero.propTypes = {
  img: PropTypes.string,
  fullpage: PropTypes.bool,
};

const Root = styled.section`
  position: relative;
`;
const Space = styled.div`
  ${(props) => (props.home ? null : vw('height', 120, 140, 160))}
`;
const Image = styled.img`
  z-index: -1;
  filter: blur(0);
  transition: 0.3s;
`;

export default Hero;
