import React, { useState } from 'react';
import styled from 'styled-components';

import Overlay from 'src/components/Header/elements/Overlay';
import TopBar from 'src/components/Header/elements/TopBar';
import Intl from 'src/components/Intl';

const Header = (props) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root isMenuActive={isMenuActive}>
          <Overlay
            isMenuActive={isMenuActive}
            setIsMenuActive={setIsMenuActive}
            data={t.navigation}
            className={locale}
          />
          {isMenuActive ? null : (
            <HeaderWrapper>
              <TopBar
                className={locale}
                isMenuActive={isMenuActive}
                setIsMenuActive={setIsMenuActive}
                data={t.navigation}
              />
            </HeaderWrapper>
          )}
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.header``;
const HeaderWrapper = styled.div``;

export default Header;
