import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Background from 'components/Background';

import content from 'src/data/content';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Register = (props) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Background />
            <Wrapper>
              <Image src={content.register.image} />
              <FormWrapper>
                <Header className={locale}>{t.register.thankyou}</Header>
              </FormWrapper>
            </Wrapper>
          </Root>
        );
      }}
    </Intl>
  );
};

Register.propTypes = {};

const Root = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  ${vw('margin-top', 90, 80, 140)}
  ${vw('margin-bottom', 50, 100, 100)}

  display: flex;
  flex-direction: column;
  @media ${media.desktop} {
    flex-direction: row;
  }
`;

const Image = styled.img`
  order: 2;
  ${vw('width', 335, 688, 713)};
  ${vw('height', 363, 746, 773)};
  ${vw('border-radius', 27, 27, 57)};

  ${vw('margin-right', 20, 40, 152)}
  ${vw('margin-left', 20, 40, 149)}
  ${vw('margin-bottom', 50, 100, 0)}
  @media ${media.desktop} {
    order: 1;
  }
`;

const Header = styled.p`
  font-weight: 200;
  text-align: center;
  ${vw('width', 326, 511, 586)}
  color: ${(props) => props.theme.color.white};
  ${vw('font-size', 30, 42, 42)};
  ${vw('line-height', 36, 50.4, 50.4)};
  ${vw('letter-spacing', 2, 2, 2)}

  @media ${media.desktop} {
    text-align: unset;
  }
`;

const FormWrapper = styled.div`
  order: 1;
  ${vw('margin-top', 60, 80, 0)}
  ${vw('margin-bottom', 60, 80, 0)}

  ${vw('margin-left', 23, 133, 0)}
  @media ${media.desktop} {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
  }
`;

export default Register;
