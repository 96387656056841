import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Content from 'components/layout/Content';
import Hero from 'components/layout/Hero';
import Page from 'components/Page';

import images from 'src/data/locationImages';

import vw from 'src/styles/utils';

const Location = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        const l = t.location;
        return (
          <Page>
            <Hero
              {...l.hero}
              hero
              img={require('src/assets/images/location/Location-Hero.jpg')}
              className={locale}
            />
            <Space />
            {l.content.map((item, index) => (
              <Content
                reverse={false}
                id={index}
                data={item}
                img={images[index].src}
                className={locale}
              />
            ))}
          </Page>
        );
      }}
    </Intl>
  );
};

const Space = styled.div`
  ${vw('height', 70, 140, 260)}
`;

export default Location;
