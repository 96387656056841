// Constants
export const TRACKING__SETQUERYVARIABLE = 'TRACKING::SETQUERYVARIABLE';

// Actions
export const setQueryVariable = (queryVariable, value) => ({
  type: TRACKING__SETQUERYVARIABLE,
  variable: queryVariable,
  value: value,
});

// Initial State
const initialState = {
  ad_tag_id: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_content: '',
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TRACKING__SETQUERYVARIABLE:
      switch (action.variable) {
        case 'ad_tag_id':
          return {
            ...state,
            ad_tag_id: action.value,
          };
        case 'utm_source':
          return {
            ...state,
            utm_source: action.value,
          };
        case 'utm_medium':
          return {
            ...state,
            utm_medium: action.value,
          };
        case 'utm_campaign':
          return {
            ...state,
            utm_campaign: action.value,
          };
        case 'utm_content':
          return {
            ...state,
            utm_content: action.value,
          };
        default:
          return {
            ...state,
          };
      }
    default:
      return state;
  }
};
