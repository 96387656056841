import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import { splitLine } from 'src/styles/utils';

import Button from 'src/components/Button';

const Separator = (props) => {
  const { title, paragraph, button, buttonLink, className } = props;

  return (
    <Root>
      {title && <Title className={className}>{splitLine(title)}</Title>}
      {paragraph && (
        <Paragraph className={className} button={button}>
          {splitLine(paragraph)}
        </Paragraph>
      )}
      {button && (
        <Button className={className} other href={buttonLink}>
          {button.text}
        </Button>
      )}
    </Root>
  );
};

Separator.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  button: PropTypes.object,
};

const Root = styled.section`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('margin-top', 40, 40, 131)}
  ${vw('gap', 0, 0, 40)}
  ${vw('margin-bottom', 40, 40, 260)}
  ${vw('padding-left', 20, 0)}
  ${vw('padding-right', 20, 0)}
`;

const Title = styled.h2`
  text-align: center;
  font-weight: 200;
  ${vw('font-size', 30, 30, 42)}
  ${vw('line-height', 36, 36, 50.4)}
  ${vw('letter-spacing', 2, 2, 2)}
`;

const Paragraph = styled.p`
  text-align: center;
  ${vw('width', '100%', 500, 686)}
  font-weight: 400;
  ${vw('font-size', 14, 14, 16)}
  ${vw('line-height', 20, 20, 24)}
  ${vw('letter-spacing', 0.32, 0.32, 0.32)}
  ${vw('margin-top', 40, 40, 0)}
  ${(props) => props.button && vw('margin-bottom', 40, 40, 0)}
`;

export default Separator;
