// images for content sections
export default {
  home: [
    {
      image: require('src/assets/images/gryphon-nest/Nest-Hero.jpg'),
      svgTitle: require('src/assets/images/gryphon-nest/logos/gryphon-nest-logo.svg'),
    },
    {
      image: require('src/assets/images/amenities/Amenities-Hero.jpg'),
    },
    {
      image: require('src/assets/images/home/hero-royal.jpg'),
    },
    {
      image: require('src/assets/images/residences/Residences-Hero.jpg'),
    },
    {
      image: require('src/assets/images/location/Location-Hero.jpg'),
    },
  ],
  amenities: [
    require('src/assets/images/amenities/amenities-1.jpg'),
    require('src/assets/images/amenities/amenities-2.jpg'),
    require('src/assets/images/amenities/amenities-3.jpg'),
    require('src/assets/images/amenities/amenities-4.jpg'),
  ],
  residences: {
    video:
      'https://player.vimeo.com/external/881828088.m3u8?s=f090c21e15f0092a464d029c7bb3932cc3b08024&logging=false',
  },
  register: {
    image: require('src/assets/images/register/register-hero.jpg'),
  },
};
