import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import Player from 'components/Video/elements/Player';
import ControlBar from 'components/Video/elements/ControlBar';

const Video = (props) => {
  const { bar, url, data } = props;

  // const [duration, setDuration] = useState(0);

  const [progress, setProgress] = useState({
    played: 0,
    playedSeconds: 1,
    loaded: 0,
    loadedSeconds: 0,
  });

  const [activeBookmark, setActiveBookmark] = useState(
    data?.bookmarks[0] || null
  );

  useEffect(() => {
    if (data) {
      if (progress.playedSeconds > 1) {
        const totalElapsedTime = progress.playedSeconds;

        let currentBookmark = data.bookmarks.filter(
          (o) => totalElapsedTime > o.start && totalElapsedTime < o.end
        );

        if (currentBookmark.length !== 0) {
          setActiveBookmark(currentBookmark[0]);
        }
      }
    }
  }, [progress]);

  return (
    <Root>
      {bar && data ? (
        <ControlBar
          bookmarks={data.bookmarks}
          progress={progress}
          activeBookmark={data && activeBookmark}
          setActiveBookmark={setActiveBookmark}
        />
      ) : null}
      <PlayerWrapper>
        <Player
          // setDuration={setDuration}
          activeBookmark={activeBookmark}
          setProgress={setProgress}
          url={url}
        />
      </PlayerWrapper>
    </Root>
  );
};

Video.propTypes = {
  data: PropTypes.object,
};

const Root = styled.section`
  ${vw('margin-right', 20, 40, 300)}
  ${vw('margin-left', 20, 40, 300)}
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const PlayerWrapper = styled.div`
  ${vw('width', 335, 688, 1162)}
  ${vw('height', 188, 386, 654)}
  position: relative;
  ${vw('border-radius', 27, 57, 57)};
  overflow: hidden;
  -webkit-transform: translateZ(0); // for safari compatibility
`;

export default Video;
