import React from 'react';

export default {
  home: {
    hero: {
      title: '極智新概念 極致新美宅',
    },
    content: [
      {
        subtitle: 'Gryphon Nest',
        paragraph:
          '極智新概念，由 Gryphon Nest 開始。Gryphon Nova 每戶皆享有全系列 Gryphon Nest 設備，包括智能家具、空氣淨化、淨水及紫外線消毒設備等，透過高科技提供更健康、舒適、方便及安全的生活環境，全面配合您現在及未來所需。',
        button: {
          text: '了解更多',
          target: '/gryphon-nest',
        },
      },
      {
        subtitle: '住宅設施',
        title: '私人會所   設想周全 ',
        paragraph:
          '更稱心的設施，配以更講究的設備，例如選用 Bang & Olufsen、Peloton 等著名品牌設備的電影院及健身中心，以先進科技為您帶來更佳娛樂及運動體驗。',
        button: {
          text: '了解更多',
          target: '/amenities',
        },
      },
      {
        subtitle: 'Royal Curator',
        title: '貼心服務   勝人一籌',
        paragraph:
          'Gryphon 一直認為，最好的住宅，不止地點卓越、設計優秀，還要為住戶提供非凡的體驗，所以 Gryphon 的業主可尊享 Royal Curator 多項精選量身定制的服務，絕非一般大廈的禮賓服務可媲。',
        link: {
          text: '瀏覽 Royal Curator 網頁',
          url: 'https://gryphonliving.com/royalcurator/',
        },
      },
      {
        subtitle: '戶型簡介',
        title: '以人為本   華實兼備 ',
        paragraph: `Gryphon Nova 的精品美宅不但注重悅目，更加講求賞心，無論間隔、設計或是選料，皆為現代生活度身而設，細心照顧您的日常所需，悉心打造您的安居空間。`,
        button: {
          text: '了解更多',
          target: '/residences',
        },
      },
      {
        subtitle: '地理位置',
        title: 'South Oak 新星  全城矚目',
        paragraph:
          '公園、公車站、商店、餐廳、名校及社區中心近在咫尺，Gryphon Nova 所在的 South Oak 不但方便，更是溫市政府全力支持發展的地段，從今至 2030 年，這區預計將增加超過 9,000 個全新職位，置業於此，潛力無可限量。',
        button: {
          text: '了解更多',
          target: '/location',
        },
      },
    ],
  },
  register: {
    text: '立即登記，獲取關於\nGryphon Nova 的最新資訊 \n(請用英文填寫以下表格)',
    thankyou: 'THANK YOU FOR REGISTERING FOR GRYPHON NOVA',
    extraText: (
      <>
        我們現正提供單對單網上或和現場導覽。立即註冊與我們的銷售團隊預約。
        <br />
        <br />1 房 $784,000 加元起
        <br />
        2 房 $1,260,900 加元起
        <br />
        3 房 $1,690,900 加元起
        <br />
        <br />
        預計 2025 年春季入伙
        <br />
        地址：銅鑼灣利園三期 4 樓
        <br />
        查詢：
        <a href="tel:+852 2156 9111" className="light">
          +852 2156 9111
        </a>
      </>
    ),
  },
  form: {
    firstName: 'First Name *',
    lastName: 'Last Name *',
    email: 'Email *',
    phone: 'Phone Number *',
    postalCode: 'Postal Code *',
    area: 'Area *',
    areaOptions: [
      {
        label: 'Hong Kong Island',
        value: 'Hong Kong Island',
      },
      {
        label: 'Kowloon',
        value: 'Kowloon',
      },
      {
        label: 'New Territories',
        value: 'New Territories',
      },
    ],
    howHeard: 'How did you hear about Gryphon Nova?',
    howHeardOptions: [
      {
        label: 'Internet',
        value: 'Internet',
      },
      {
        label: 'Newspaper',
        value: 'Newspaper',
      },
      {
        label: 'Magazine',
        value: 'Magazine',
      },
      {
        label: 'Walk/Drive By',
        value: 'Walk/Drive By',
      },
      {
        label: 'Friend/Family',
        value: 'Friend/Family',
      },
      {
        label: 'Others',
        value: 'Others',
      },
    ],
    realtor: 'are you a realtor?*',
    realtorOptions: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    typeHome: 'Type of Home',
    typeHomeOptions: [
      {
        label: '1 Bedroom',
        value: '1 Bedroom',
      },
      {
        label: '2 Bedroom',
        value: '2 Bedroom',
      },
      {
        label: '3 Bedroom',
        value: '3 Bedroom',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
    ],
    typeBudget: 'Budget Range',
    typeBudgetOptions: [
      {
        label: '$800,000 - $1,200,000',
        value: '$800,000 - $1,200,000',
      },
      {
        label: '$1,200,000 - $1,600,000',
        value: '$1,200,000 - $1,600,000',
      },
      {
        label: '$1,600,000 +',
        value: '$1,600,000 +',
      },
    ],
    disclaimer:
      'I hereby consent to receive emails from Gryphon Development and their respective current and future affiliates and subsidiaries. This may include pricing, floor plans for current and future products, sales updates, event invitations, announcements, corporate newsletters, and other contact. I understand that I can unsubscribe at any time. *',
    registerButton: 'Register',
  },
  navigation: {
    languages: [
      { title: 'en', url: '/#' },
      { title: '繁', url: '/#' },
      { title: '简', url: '/#' },
    ],
    side: [
      { title: '預約參觀', url: '/appointment' },
      { title: '聯繫我們', url: '/contact' },
      { title: '立即登記', url: '/register' },
    ],
    menu: [
      { title: 'Gryphon Nest', url: '/gryphon-nest' },
      { title: '住宅設施', url: '/amenities' },
      { title: '戶型簡介', url: '/residences' },
      { title: '地理位置', url: '/location' },
    ],
  },
  footer: {
    disclaimer:
      'This is not an offer for sale and is for information purposes only. An offer for sale may only be made in conjunction with a Disclosure Statement. E. & O.E. The above images or drawings are for illustration purposes only. The developer reserves the right to alter size, price, design and/or finishings at any time.',
    sections: {
      sales: {
        title: '體驗展館',
        text: '銅鑼灣利園三期 4 樓',
      },
      contact: {
        title: '聯繫我們',
        info: {
          phone: 'P: 604-620-8295',
          email: 'E: sales@gryphonnova.com',
        },
      },
      social: {
        title: '社交媒體',
      },
    },
  },
  location: {
    hero: {
      subtitle: '地理位置',
      title: 'South Oak 新星  全城矚目',
      paragraph:
        '公園、公車站、商店、餐廳、名校及社區中心近在咫尺，Gryphon Nova 所在的 South Oak 不但方便，更是溫市政府全力支持發展的地段，從今至 2030 年，這區預計將增加超過 9,000 個全新職位，置業於此，潛力無可限量。',
    },
    content: [
      {
        title: '靜中帶旺',
        paragraph: `Gryphon Nova 所在的 South Oak，是溫哥華發展最迅速的社區之一，齊備公園、各式商店、餐廳、社區中心及名校 Sir Winston Churchill Secondary 等。踏單車、乘公車或駕車即可便捷地前往溫哥華市中心。`,
      },
      {
        title: '四通八達',
        paragraph: `Gryphon Nova 位於溫哥華西區中心地段 Oak 夾 67 街，步行可達 Canada Line 天車線、Granville 街及 Marine Gateway 購物區；不到 20 分鐘車程則可抵達 UBC 大學、市中心、列治文、北溫、素里、三角洲等區。`,
      },
      {
        title: '信步即達讓人心曠神怡的社區公園',
      },
      {
        title: '名校、遊樂場與社區中心，讓子女健康成長',
      },
      {
        title: '各式商店、餐廳及超市近在咫尺',
      },
    ],
  },
  residences: {
    hero: {
      subtitle: '戶型簡介',
      title: '以人為本   華實兼備 ',
      paragraph:
        '無論間隔、設計或是選料，Gryphon Nova 皆為現代生活量身而設，美觀與實用兼備，細心照顧您的日常所需，安居於此，倍覺愜意。',
    },
    content: [
      {
        title: '精選戶型',
        paragraph: `Gryphon Nova 的戶型提供更高靈活性，加上 9 呎樓底 (頂層特色戶及次頂層特色戶擁有 10 呎樓底)、實用間隔及智能家具，給您更大創意空間，打造心儀家居。`,
        planView: true,
        button: {
          text: '立即登記，查看更多戶型',
          target: '/register',
        },
        image: [
          {
            text: '一睡房戶型',
          },
          {
            text: '兩睡房戶型',
          },
          {
            text: '三睡房戶型',
          },
        ],
      },
      {
        title: '廚房',
        paragraph:
          '廚房設計注重時尚美觀，更重視實用和效率。最先進的 Fisher & Paykel 電器、抗菌的石英檯面、合成花崗岩洗滌槽以及充足儲物空間，助您輕鬆享受下廚之樂。',
        image: [
          {
            text: '淺色',
          },
          {
            text: '深色',
          },
        ],
      },
      {
        title: '浴室',
        paragraph:
          '浴室設計靈感源自高級 Spa，抗菌的石英檯面、嵌入式水槽、設有緩衝裝置的抽屜、隱藏 LED 氛圍燈的鏡子、香檳銅水龍頭等，彰顯華貴雅緻的氣派。',
        image: [
          {
            text: '淺色',
          },
          {
            text: '深色',
          },
        ],
      },
      {
        title: '升級配套',
        paragraph:
          '由室內設計大師特別定制的 Nova 色系，靈感來自木材和大理石色調，配襯香檳銅水龍頭，家居氣派進一步昇華。\n\n其他升級配套包括：\n- 特厚廚房石英檯面連木砧板\n- 名牌 Gaggenau 30 吋壁掛式焗爐\n- 名牌 Gaggenau 30 吋隱藏式冰箱\n',
        image: [
          {
            text: '廚房',
          },
          {
            text: '浴室',
          },
        ],
      },
    ],
    separator: {
      title: '考究設計',
      paragraph:
        'Gryphon Nova的美宅設計及選材一絲不苟，非常切合生活需求，當中包括擴大儲物空間、增加房間用途的靈活性等。',
      button: {
        text: '下載戶型配套',
        target: '',
      },
    },
  },
  amenities: {
    hero: {
      subtitle: '住宅設施',
      title: '私人會所   設想周全 ',
      paragraph:
        'Gryphon Nova 精心安排的會所設施，僅供 43 戶住宅專享，當中包括電影院、健身中心、花園燒烤區、共享辦公室、休閒會客廳及戶外休閒區，安居於此，閒暇玩樂或日常辦公，完全不假外求。',
    },
    content: [
      {
        title: 'Nova 休閒會客廳',
        paragraph: `設計時尚的多用途休閒廳，給您舒適靜謐的空間工作、進行會議、與親朋共聚、或是閒暇放鬆心情。`,
      },
      {
        title: 'Nova 電影院',
        paragraph:
          'Nova 電影院特別選用著名品牌 Bang & Olufsen 的高級音響系統，音效更出色，讓您更投入電影世界。',
      },
      {
        title: '健身中心',
        paragraph:
          'Gryphon Nova 的健身中心配備著名品牌 Peloton 的先進健身單車及跑步機，提供精準及互動的運動訓練，助您更有效地保持健康或提高體能。',
      },
      {
        title: '智能保安系統及智能包裹儲物櫃',
        paragraph:
          '智能保安系統監控入口通道、停車場和大堂區域，讓您倍感安心。智能包裹儲物櫃則讓您即時透過手機收到包裹通知，並隨時掃描二維碼拿取包裹。',
      },
    ],
  },
  nest: {
    hero: {
      subtitle: 'Gryphon Nest',
      paragraph:
        'Gryphon Nest 一系列高科技設備，無微不至照顧生活所需，當中包括智能家具，睡床、書桌、儲物櫃懸掛於天花，您可隨時以手機遙控升降，改變房間用途，靈活配合需要；空氣淨化、淨水及紫外線消毒設備則為您提供健康的生活環境。Gryphon Nest 還有更多貼心的先進設施，讓您的生活更便捷、更舒適。',
    },
    separator: {
      title: '極智設備  極致周到',
      paragraph:
        'Gryphon Nest 一系列高科技設備，無微不至照顧生活所需，當中包括智能家具，睡床、書桌、儲物櫃懸掛於天花，您可隨時以手機遙控升降，改變房間用途，靈活配合需要；空氣淨化、淨水及紫外線消毒設備則為您提供健康的生活環境。Gryphon Nest 還有更多貼心的先進設施，讓您的生活更便捷、更舒適。',
    },
    video: {
      src: 'https://player.vimeo.com/external/881809006.m3u8?s=653975af349faa46818edd19a7528f758fb158b6&logging=false',
      bookmarks: [
        {
          title: 'Water\nFiltration',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/water-tc.svg'),
          start: 4,
          end: 19,
        },
        {
          title: 'Air\nFiltration',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/air-tc.svg'),
          start: 20,
          end: 33,
        },
        {
          title: 'UV Sanitizing\nNiche',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/uv-tc.svg'),
          start: 33,
          end: 56,
        },
        {
          title: 'Home\nApp',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/app-tc.svg'),
          start: 56,
          end: 77,
        },
        {
          title: 'Triple Pane\nWindow',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/window-tc.svg'),
          start: 77,
          end: 97,
        },
        {
          title: 'Smart\nParcel Lockers',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/locker-tc.svg'),
          start: 97,
          end: 112,
        },
        {
          title: 'License Plate\nRecognition ',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/plate-tc.svg'),
          start: 112,
          end: 117,
        },
        {
          title: 'EV\nCharging',
          icon: require('src/assets/images/gryphon-nest/bookmarks/tc/ev-tc.svg'),
          start: 117,
          end: 123,
        },
      ],
    },
  },
  contact: {
    hero: {
      title: (
        <>
          我們很高興
          <br />
          能為您服務
        </>
      ),
      subtitle: '聯繫我們',
    },
    vancouver: {
      title: '溫哥華展銷中心',
    },
    hk: {
      title: '香港展銷中心',
    },
    directions: '開啟地圖',
  },
};
