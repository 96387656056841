import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Hero from 'components/layout/Hero';
import Video from 'components/Video/Video';
import Page from 'components/Page';

import vw from 'src/styles/utils';

const Nest = (props) => {
  return (
    <Intl>
      {(t, { locale }) => {
        const n = t.nest;
        return (
          <Page>
            <Hero
              {...n.hero}
              hero
              svgTitle={require('src/assets/images/gryphon-nest/logos/gryphon-nest-logo.svg')}
              img={require('src/assets/images/gryphon-nest/Nest-Hero.jpg')}
              className={locale}
            />
            {/* <Space />
            <Separator
              title={n.separator.title}
              paragraph={n.separator.paragraph}
              className={locale}
            /> */}
            <Space />

            <Video data={n.video} url={n.video.src} bar />
            <Space />
          </Page>
        );
      }}
    </Intl>
  );
};

const Space = styled.div`
  ${vw('height', 70, 140, 180)}
`;

export default Nest;
