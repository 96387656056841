export default [
  {
    image: {
      en: [
        {
          src: require('src/assets/images/residences/featured-1-bed-en.png'),
        },
        {
          src: require('src/assets/images/residences/featured-2-bed-en.png'),
        },
        {
          src: require('src/assets/images/residences/featured-3-bed-en.png'),
        },
      ],
      tc: [
        {
          src: require('src/assets/images/residences/featured-1-bed-tc.png'),
        },
        {
          src: require('src/assets/images/residences/featured-2-bed-tc.png'),
        },
        {
          src: require('src/assets/images/residences/featured-3-bed-tc.png'),
        },
      ],
      sc: [
        {
          src: require('src/assets/images/residences/featured-1-bed-sc.png'),
        },
        {
          src: require('src/assets/images/residences/featured-2-bed-sc.png'),
        },
        {
          src: require('src/assets/images/residences/featured-3-bed-sc.png'),
        },
      ],
    },
  },
  {
    image: [
      {
        src: require('src/assets/images/residences/kitchen-light.jpg'),
      },
      {
        src: require('src/assets/images/residences/kitchen-dark.jpg'),
      },
    ],
  },
  {
    image: [
      {
        src: require('src/assets/images/residences/ensuite-light.jpg'),
      },
      {
        src: require('src/assets/images/residences/ensuite-dark.jpg'),
      },
    ],
  },
  {
    image: [
      {
        src: require('src/assets/images/residences/upgrade-kitchen.jpg'),
      },
      {
        src: require('src/assets/images/residences/upgrade-ensuite.jpg'),
      },
    ],
  },
];
