import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import Intl from 'components/Intl';
import Button from 'components/Button';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import mapStyles from 'src/data/map';

const GoogleMapBlock = (props) => {
  const {
    title,
    phone,
    email,
    address,
    directions,
    center,
    logoCoord,
    zoom,
    topMargin,
  } = props;

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      return window.open(
        `https://www.google.com/maps/dir//${directions}`,
        '_blank'
      );
    }
  };

  const success = (position) => {
    window.open(
      `https://www.google.com/maps/dir/${position.coords.latitude},${position.coords.longitude}/${directions}`
    );
  };

  const error = () => {
    window.open(`https://www.google.com/maps/dir//${directions}`, '_blank');
  };

  return (
    <Intl>
      {(t, { locale }) => (
        <Root locale={locale} topMargin={topMargin}>
          <Content>
            <h2 className="white">{title}</h2>
            <a href={`tel:${phone}`} className="white">
              <p className="white">{phone}</p>
            </a>
            <a href={`mailto:${email}`} className="white underline">
              <p className="white">{email}</p>
            </a>
            <br />
            <br />
            <p className="white">{address}</p>
            <Space />
            <Button
              handleClick={() => getLocation()}
              text={t.contact.directions}
              transparent
            >
              <span>{t.contact.directions}</span>
            </Button>
          </Content>
          <Wrapper>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw',
              }}
              defaultCenter={center}
              zoom={zoom}
              options={{
                styles: mapStyles,
                zoomControl: false,
                fullscreenControl: false,
              }}
            >
              <Logo
                lat={logoCoord.lat}
                lng={logoCoord.lng}
                src={require('src/assets/images/contact/gn_map_pin.png')}
              />
            </GoogleMapReact>
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

GoogleMapBlock.propTypes = {
  title: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.object,
  center: PropTypes.object,
  topMargin: PropTypes.bool,
};

const Root = styled.div`
  padding: 0 ${vwMobile(20)};
  ${vw('margin-bottom', 80, 160, 120)}
  ${vw('margin-top', 36, 160, 260)}
  h2 {
    font-family: ${(props) => props.theme.font[props.locale]};
    font-weight: 300;
    ${vw('font-size', 30, 30, 42)}
    ${vw('line-height', 36, 36, 50)}
    ${vw('letter-spacing', 2)}
    ${vw('margin-bottom', 30, 40)}
  }
  p {
    font-family: ${(props) => props.theme.font[props.locale]};
    font-weight: 400;
    ${vw('font-size', 16)}
    ${vw('line-height', 24)}
    ${vw('letter-spacing', 0.3)}
  }
  .underline {
    text-decoration: underline;
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(40)};
  }
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${vwDesktop(60)} 0 ${vwDesktop(163)};

    a {
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    width: 100%;
  }
  @media ${media.tablet} {
    button {
      width: auto;
    }
  }
  @media ${media.desktop} {
    align-items: flex-start;
    text-align: left;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${vwMobile(189)};
  position: relative;
  margin-top: ${vwMobile(36)};
  border-radius: ${vwMobile(17)};
  overflow: hidden;
  @media ${media.tablet} {
    height: ${vwTablet(388)};
    margin-top: ${vwTablet(80)};
    border-radius: ${vwTablet(27)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1044)};
    height: ${vwDesktop(640)};
    border-radius: ${vwDesktop(57)};
    margin-top: 0;
  }
`;

const Logo = styled.img`
  display: block;
  transform: translate(-50%, -50%);
  ${vw('width', 60, 96, 120)}
`;

const Space = styled.div`
  ${vw('padding-top', 20, 40)}
`;

export default GoogleMapBlock;
