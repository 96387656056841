// images for hero sections
export default {
  home: {
    svg: require('src/assets/images/home/the-art-of-innovation.svg'),
    image: require('src/assets/images/home/hero-landing.jpg'),
  },
  amenities: {
    image: require('src/assets/images/amenities/Amenities-Hero.jpg'),
  },
};
