import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const Input = (props) => {
  const { label, name, id, required, type } = props;

  const [value, setValue] = useState('');

  return (
    <Root>
      <input
        type={type}
        name={name}
        id={id}
        className={`
          form-input ${value !== '' && 'active'}`}
        required={required}
        onChange={(e) => setValue(e.target.value)}
      />
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <span />
    </Root>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  /* ${vw('max-width', 288, 468, 494)} */
  ${vw('height', 44, 52, 52)}
  ${vw('margin-bottom', 20, 20, 20)}

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #f0f2f2;
  }
  label {
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 0;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};

    ${vw('font-size', 12, 16)}
    ${vw('line-height', 22)}
    ${vw('letter-spacing', 1)}
  }
  input {
    width: 100%;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;

    ${vw('font-size', 16)}
    ${vw('line-height', 22)}
    ${vw('letter-spacing', 1)}
    ${vw('height', 20, 24)}
    ${vw('margin-bottom', 6, 7)}

    &:-webkit-autofill {
      appearance: none;
      box-shadow: 0 0 0px 1000px transparent inset;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }
  }
  input:focus {
    background-color: transparent;
    outline: 0;
    border: none;
  }
  input:focus + label,
  input.active + label {
    top: 0;
    transform: translateY(0);
    ${vw('font-size', 9)}
    ${vw('line-height', 12)}
    ${vw('letter-spacing', 2.25)}
  }
`;

export default Input;
