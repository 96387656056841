import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import backgroundImage from 'src/assets/images/background.png';

import media from 'src/styles/media';
import { vwMobile, vwTablet } from 'src/styles/utils';

const Background = (props) => {
  const { home, handleLoad } = props;

  gsap.registerPlugin(ScrollTrigger);

  const $background = useRef();

  return (
    <Root backgroundImage={backgroundImage} home={home}>
      <BackgroundImage
        src={backgroundImage}
        alt=""
        ref={$background}
        onLoad={() => handleLoad($background)}
      />
    </Root>
  );
};

Background.propTypes = {
  home: PropTypes.bool,
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${(props) => (props.home ? '100%' : `calc(100% + ${vwMobile(200)})`)};
  max-height: 100%;
  overflow: hidden;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  @media ${media.tablet} {
    height: ${(props) =>
      props.home ? '100%' : `calc(100% + ${vwTablet(200)})`};
    height: 100%;
  }
  @media ${media.desktop} {
    height: 100%;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  min-height: 100%;
  @media ${media.desktop} {
    width: 100%;
    height: auto;
    min-height: auto;
  }
`;

export default Background;
