// Constants
export const LOADER__SETHASLOADED = 'LOADER::SETHASLOADED';

// Actions
export const setHasPageLoaded = (hasLoaded) => ({
  type: LOADER__SETHASLOADED,
  hasLoaded,
});

// Initial State
const initialState = {
  hasPageLoad: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADER__SETHASLOADED:
      return {
        ...state,
        hasPageLoad: action.hasLoaded,
      };
    default:
      return state;
  }
};
