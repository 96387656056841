import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const LanguageSelector = (props) => {
  const { className } = props;
  const $getQuery =
    window.location.search.substring(1) !== ''
      ? '?' + window.location.search.substring(1)
      : '';

  return (
    <Intl>
      {(t, { locale }) => (
        <Root className={className}>
          <Language className={className}>
            <Link
              className={className}
              to={'/en' + $getQuery}
              active={locale === 'en' ? 'true' : 'false'}
            >
              EN
            </Link>{' '}
            |{' '}
            <Link
              className={className}
              to={'/' + $getQuery}
              active={locale === 'tc' ? 'true' : 'false'}
            >
              繁
            </Link>
          </Language>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.white};
  font-weight: 500;
  ${vw('font-weight', 13, 13, 14)}
  ${vw('line-height', 15.87, 15.87, 17.09)}
  ${vw('letter-spacing', 1, 1, 3)}
`;

const Language = styled.div`
  display: flex;
  a {
    display: block;
    color: #f0f2f2;
    font-weight: 500;
    ${vw('font-weight', 13, 13, 14)};
    ${vw('line-height', 15.87, 15.87, 17.09)};
    ${vw('letter-spacing', 1, 1, 3)}
    /* ${vw('margin-bottom', 10, 10)} */
    ${vw('padding-right', 13, 15)}
    ${vw('padding-left', 13, 15)}
    /* ${vw('padding-top', 2, 8)} */
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: center;
  }
`;

export default LanguageSelector;
