import React from 'react';

export default {
  home: {
    content: [
      {
        subtitle: 'Gryphon Nest',
        paragraph:
          'The Art of Innovation begins with Gryphon Nest, a collection of technological features that elevates living experiences. From smart furniture system and climate control to medical-grade air and water filtration system - ultimate comfort lives here.',
        button: {
          text: 'Explore',
          target: '/gryphon-nest',
        },
      },
      {
        subtitle: 'Amenities',
        title: 'Artfully Crafted Spaces To Live, Work & Play',
        paragraph:
          'Here, the comforts of home go beyond just your suite. Immerse yourself in an expansive collection of thoughtfully designed amenities, exclusively shared by only 43 private residences. The amenities at Nova include a movie theatre, BBQ terrace, premier fitness centre, meeting rooms and outdoor lounge.',
        button: {
          text: 'Explore',
          target: '/amenities',
        },
      },
      {
        subtitle: 'Royal Curator',
        title: 'Your Life Perfected',
        paragraph:
          'Our Royal Curator program goes beyond common offerings and delivers an exceptional array of personalized services.',
        link: {
          text: 'Visit Website',
          url: 'https://gryphonliving.com/royalcurator/',
        },
      },
      {
        subtitle: 'Residences',
        title: 'The New Standard',
        paragraph: `Design isn’t simply about how it looks. It's an art. It’s about how it elevates your surroundings and integrates with your lifestyle. More than ever, true luxury is defined by a home that is tailored around your everyday wants and needs. From smart technology to intuitive space planning, life at Gryphon Nova is built with your future in mind.`,
        button: {
          text: 'Explore',
          target: '/residences',
        },
      },
      {
        subtitle: 'LOCATION',
        title: 'Rising with South Oak',
        paragraph:
          'Whether you’re looking for community services, local school or shopping, you’ll find it here. And it’s growing, with more than 9,000 new jobs expected to be located here by 2030. That is why South Oak has become one of the most sought-after living areas in Vancouver West.',
        button: {
          text: 'Explore',
          target: '/location',
        },
      },
    ],
  },
  register: {
    text: '',
    thankyou: 'THANK YOU FOR REGISTERING FOR GRYPHON NOVA',
    extraText: (
      <>
        We now offer both private virtual and in-person appointments. Register
        to book an appointment with our Sales Team today.
        <br />
        <br />1 bedroom starting at C$784,000
        <br />
        2 bedrooms starting at C$1,260,900
        <br />
        3 bedrooms starting at C$1,690,900
        <br />
        <br />
        Estimated completion Spring 2025
        <br />
        Sales Gallery: 4/F, Lee Garden 3, Causeway Bay, Hong Kong
        <br />
        Enquiry:{' '}
        <a href="tel:+852 2156 9111" className="light">
          +852 2156 9111
        </a>
      </>
    ),
  },
  form: {
    firstName: 'First Name *',
    lastName: 'Last Name *',
    email: 'Email *',
    phone: 'Phone Number *',
    postalCode: 'Postal Code *',
    area: 'Area *',
    areaOptions: [
      {
        label: 'Hong Kong Island',
        value: 'Hong Kong Island',
      },
      {
        label: 'Kowloon',
        value: 'Kowloon',
      },
      {
        label: 'New Territories',
        value: 'New Territories',
      },
    ],
    howHeard: 'How did you hear about Gryphon Nova?',
    howHeardOptions: [
      {
        label: 'Internet',
        value: 'Internet',
      },
      {
        label: 'Newspaper',
        value: 'Newspaper',
      },
      {
        label: 'Magazine',
        value: 'Magazine',
      },
      {
        label: 'Walk/Drive By',
        value: 'Walk/Drive By',
      },
      {
        label: 'Friend/Family',
        value: 'Friend/Family',
      },
      {
        label: 'Others',
        value: 'Others',
      },
    ],
    realtor: 'are you a realtor?*',
    realtorOptions: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    typeHome: 'Type of Home',
    typeHomeOptions: [
      {
        label: '1 Bedroom',
        value: '1 Bedroom',
      },
      {
        label: '2 Bedroom',
        value: '2 Bedroom',
      },
      {
        label: '3 Bedroom',
        value: '3 Bedroom',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
    ],
    typeBudget: 'Budget Range',
    typeBudgetOptions: [
      {
        label: '$800,000 - $1,200,000',
        value: '$800,000 - $1,200,000',
      },
      {
        label: '$1,200,000 - $1,600,000',
        value: '$1,200,000 - $1,600,000',
      },
      {
        label: '$1,600,000 +',
        value: '$1,600,000 +',
      },
    ],
    disclaimer:
      'I hereby consent to receive emails from Gryphon Development and their respective current and future affiliates and subsidiaries. This may include pricing, floor plans for current and future products, sales updates, event invitations, announcements, corporate newsletters, and other contact. I understand that I can unsubscribe at any time. *',
    registerButton: 'Register',
  },
  navigation: {
    languages: [
      { title: 'en', url: '/#' },
      { title: '繁', url: '/#' },
      { title: '简', url: '/#' },
    ],
    side: [
      { title: 'Appointment', url: '/appointment' },
      { title: 'Contact', url: '/contact' },
      { title: 'Register', url: '/register' },
    ],
    menu: [
      { title: 'Gryphon Nest', url: '/gryphon-nest' },
      { title: 'Amenities', url: '/amenities' },
      { title: 'Residences', url: '/residences' },
      { title: 'Location', url: '/location' },
    ],
  },
  footer: {
    disclaimer:
      'This is not an offer for sale and is for information purposes only. An offer for sale may only be made in conjunction with a Disclosure Statement. E. & O.E. The above images or drawings are for illustration purposes only. The developer reserves the right to alter size, price, design and/or finishings at any time.',
    sections: {
      sales: {
        title: 'SHOWROOM',
        text: '4/F, LEE GARDEN THREE, Causeway Bay, Hong Kong',
      },
      contact: {
        title: 'Contact',
        info: {
          phone: 'P: 604-620-8295',
          email: 'E: sales@gryphonnova.com',
        },
      },
      social: {
        title: 'Social',
      },
    },
  },
  location: {
    hero: {
      subtitle: 'location',
      title: 'Rising with South Oak',
      paragraph:
        'Whether you’re looking for community services, local school or shopping, you’ll find it here. And it’s growing, with more than 9,000 new jobs expected to be located here by 2030. That is why South Oak has become one of the most sought-after neighbourhoods in Vancouver West.',
    },
    content: [
      {
        title: 'Vancouver’s most coveted new neighbourhood.',
        paragraph: `Parks, schools, shopping, community centres and walking trails - it's all here in the South Oak neighbourhood. Located at Oak Street and 67th Avenue, Downtown Vancouver is easily accessible via public transit, bikes and cars.`,
      },
      {
        title: 'Modern Living made easy.',
        paragraph: `Centrally located on Vancouver's West side, Gryphon Nova is a short walking distance to Granville Street, Canada Line rapid transit and Marine Gateway. Downtown Vancouver, Richmond, North Vancouver, Surrey and Delta are all within a 20-minute drive.`,
      },
      {
        title: 'Greenery and serenity at the foot of your doorstep.',
      },
      {
        title: 'Steps to schools, playgrounds and community centre.',
      },
      {
        title: 'Minutes to shops, restaurants and supermarket.',
      },
    ],
  },
  residences: {
    hero: {
      subtitle: 'residences',
      title: 'The new standard.',
      paragraph:
        'Design isn’t simply about how it looks.\nIt’s about how it elevates your surroundings and integrates with your lifestyle. More than ever, true luxury is defined by a home that is tailored around your everyday wants and needs. From smart technology to intuitive space planning, life at Gryphon Nova is built with your future in mind.',
    },
    content: [
      {
        title: 'Featured Plans',
        paragraph: `The homes at Gryphon Nova offer unparalleled flexibility to create the space you’ve always envisioned. 9’ high ceilings (10' for penthhouse and sub-penthhouse), smart storage and efficient space design are the principle features of every floorplan.`,
        planView: true,
        button: {
          text: 'REGISTER NOW TO SEE MORE PLANS',
          target: '/register',
        },
        image: [
          {
            text: '1 bed',
          },
          {
            text: '2 bed',
          },
          {
            text: '3 bed',
          },
        ],
      },
      {
        title: 'Kitchen',
        paragraph:
          'Our sleek and stylish kitchens have been designed with efficiency in mind. State of the art Fisher & Paykel appliances, bacteria-resistant quartz countertops with backsplash, and ample storage for all your kitchen essentials.\n\n• Spacious modern kitchen with duo-tone cabinets\n• Under-cabinet lighting and soft-close hardware\n• Granite composite undermount sink can withstand heat up to 530 fahrenheit\n• 24” black stainless trim/integrated appliances package for 1-bedroom suites\n• 30” black stainless trim/integrated appliances package for 2 & 3 bedroom suites',
        image: [
          {
            text: 'Light',
          },
          {
            text: 'Dark',
          },
        ],
      },
      {
        title: 'Ensuite\n& Bathroom',
        paragraph:
          'Our luxury spa-inspired bathroom vanity features a bacteria-resistant quartz countertop and mirror equipped with LED ambient lighting. Everyday comfort is elevated with artful touches and sophisticated design including soft-close drawers, undermount sink and champagne bronze faucets.',
        image: [
          {
            text: 'Light',
          },
          {
            text: 'Dark',
          },
        ],
      },
      {
        title: 'Premium Upgrades',
        paragraph:
          'Enhance your home with our bespoke colour scheme – Nova, carefully curated by our master interior designer. A deluxe combination of colour and texture inspired by the neutral earth tones of wood and marble, accented by champagne bronze faucets for a luxurious and unified aesthetic. Cooking enthusiasts can also choose to upgrade to an extra thick countertop and butcher block table top.\n\nUpgrade also includes:\n30” Gaggenau wall oven\n30” Gaggenau panel fridge',
        image: [
          {
            text: 'Kitchen',
          },
          {
            text: 'Ensuite',
          },
        ],
      },
    ],
    separator: {
      title: 'Intelligent interiors built around you.',
      paragraph:
        'Every square inch of Gryphon Nova is designed with form and function in mind. Enjoy the benefits of a smart furniture system that can transform a bedroom into a home office, a yoga studio or anything you desire with a touch on your smart phone.',
      button: {
        text: 'Download Feature Sheet',
        target: '',
      },
    },
  },
  amenities: {
    hero: {
      subtitle: 'amenities',
      title: 'Artfully Crafted Spaces To Live, Work & Play',
      paragraph:
        'Here, the comforts of home go beyond just your suite. Immerse yourself in an expansive collection of thoughtfully designed amenities, including a movie theatre, BBQ terrace, premier fitness centre, meeting rooms, and outdoor lounge.',
    },
    content: [
      {
        title: 'Nova Lounge',
        paragraph: `As a stylish extension of your living room or home office, the Nova Lounge is a tastefully appointed meeting hub for social gatherings, meetings and business networking events.`,
      },
      {
        title: 'Nova Theatre',
        paragraph:
          'An impressive retreat from the everyday routine. Residents can immerse themselves in a state-of-the-art screening theatre experience equipped with a Bang & Olufsen sound system.',
      },
      {
        title: 'Premier\nFitness Centre',
        paragraph:
          'Here, it’s easier to keep fitness at the forefront of your modern lifestyle. Enjoy a fully-equipped workout centre featuring Peloton treadmills and bikes with personalized and interactive training sessions.',
      },
      {
        title: 'Lobby with Smart\nParcel Locker',
        paragraph:
          'With advanced security, comes greater peace of mind. Key-less entry system, one-way camera and in-suite or mobile intercom are just a few of the features that make the entry process both secure and seamless. Plus, our Smart Parcel Lockers allows you to pick up your packages with a security code sent to your mobile device.',
      },
    ],
  },
  nest: {
    hero: {
      subtitle: 'Gryphon Nest',
      paragraph:
        'The Art of Innovation begins with Gryphon Nest, a collection of technological features that elevates living experiences. From smart furniture system and climate control to medical-grade air and water filtration system - ultimate comfort lives here.',
    },
    separator: {
      paragraph:
        'The Art of Innovation begins with Gryphon Nest, a collection of technological features that elevates living experiences. From smart furniture system and climate control to medical-grade air and water filtration system - ultimate comfort lives here.',
    },
    video: {
      src: 'https://player.vimeo.com/external/881809181.m3u8?s=c0a89dc50fcbdbb300fc63dd2ab4cb10c2a5f58b&logging=false',
      bookmarks: [
        {
          title: 'Water\nFiltration',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/water-en.svg'),
          start: 4,
          end: 19,
        },
        {
          title: 'Air\nFiltration',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/air-en.svg'),
          start: 20,
          end: 33,
        },
        {
          title: 'UV Sanitizing\nNiche',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/uv-en.svg'),
          start: 33,
          end: 56,
        },
        {
          title: 'Home\nApp',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/app-en.svg'),
          start: 56,
          end: 77,
        },
        {
          title: 'Triple Pane\nWindow',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/window-en.svg'),
          start: 77,
          end: 97,
        },
        {
          title: 'Smart\nParcel Lockers',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/locker-en.svg'),
          start: 97,
          end: 112,
        },
        {
          title: 'License Plate\nRecognition ',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/plate-en.svg'),
          start: 112,
          end: 117,
        },
        {
          title: 'EV\nCharging',
          icon: require('src/assets/images/gryphon-nest/bookmarks/en/ev-en.svg'),
          start: 117,
          end: 123,
        },
      ],
    },
  },
  contact: {
    hero: {
      title: 'Inquiries',
      subtitle: 'Contact',
    },
    vancouver: {
      title: (
        <>
          Vancouver
          <br />
          Sales Centre
        </>
      ),
    },
    hk: {
      title: 'Hong Kong Office',
    },
    directions: 'Get Directions',
  },
};
