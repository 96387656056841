export default [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/GryphondevelopmentHK/',
    icon: require('src/assets/images/footer/social/facebook-icon.svg'),
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/gryphondevelopment/',
    icon: require('src/assets/images/footer/social/instagram-icon.svg'),
  },
  {
    name: 'Youtube',
    url: 'https://www.youtube.com/channel/UCDXwjl5nvhQfCdp4_rHY1Dg/featured',
    icon: require('src/assets/images/footer/social/youtube-icon.svg'),
  },
  // {
  //   name: 'WeChat',
  //   url: '/',
  //   icon: require('src/assets/images/footer/social/wechat-icon.svg'),
  // },
];
