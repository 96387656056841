import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player/file';

import vw from 'src/styles/utils';

const Player = (props) => {
  const { setProgress, activeBookmark, url } = props;
  const videoRef = useRef(0);

  useEffect(() => {
    videoRef.current.seekTo(activeBookmark?.start);
  }, [activeBookmark]);

  // const handleDuration = (duration) => {
  //   setDuration(duration);
  // };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  return (
    <VideoPlayer
      autoPlay
      ref={videoRef}
      playsinline={true}
      controls={true}
      muted={true}
      volume={0.5}
      playing={true}
      width={'100%'}
      height={'100%'}
      onProgress={handleProgress}
      // onDuration={handleDuration}
      url={url}
      loop={true}
    />
  );
};

Player.propTypes = {
  url: PropTypes.string,
};

const VideoPlayer = styled(ReactPlayer)`
  position: relative;
  top: 0px;
  left: 0px;
  /* ${vw('border-radius', 27, 57, 57)}; */
  overflow: hidden;
  transform: scale(1.5);
  -webkit-transform: translateZ(0); // for safari compatibility
`;

export default Player;
