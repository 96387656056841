import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Bookmark from 'components/Video/elements/Bookmark';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const ControlBar = (props) => {
  const { bookmarks, progress, activeBookmark, setActiveBookmark } = props;
  return (
    <Root>
      {bookmarks.map((item, index) => (
        <Bookmark
          key={index}
          title={item.title}
          start={item.start}
          end={item.end}
          wholeBookmark={item}
          icon={item.icon}
          progress={progress}
          activeBookmark={activeBookmark}
          setActiveBookmark={setActiveBookmark}
        />
      ))}
    </Root>
  );
};

ControlBar.propTypes = {
  bookmarks: PropTypes.array,
  progress: PropTypes.object,
  activeBookmark: PropTypes.object,
  setActiveBookmark: PropTypes.func,
};

const Root = styled.aside`
  justify-self: center;

  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  ${vw('margin-bottom', 31, 31, 31)}
  ${vw('margin-left', 0, 172, 0)}
  ${vw('margin-right', 0, 172, 0)}

  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default ControlBar;
