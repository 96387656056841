// includes icon paths
export default {
  navigation: {
    close: require('src/assets/images/header/icon-close.svg'),
    menu: require('src/assets/images/header/icon-menu.svg'),
  },

  footer: {
    logos: {
      gryphonNova: require('src/assets/images/footer/logos/gryphon-nova-logo.svg'),
      gryphon: require('src/assets/images/footer/logos/gryphon-logo.svg'),
    },
  },
};
