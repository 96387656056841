import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media.js';
import vw from 'src/styles/utils.js';
import { splitLine } from 'src/styles/utils';

import ImageContent from 'components/layout/ImageContent';
import Button from 'components/Button';

const Content = (props) => {
  const {
    data,
    reverse,
    id,
    multi,
    img,
    floorplan,
    images,
    darkButtons,
    className,
    buttonLink,
    link,
    getLocaleURL,
  } = props;

  // checks if the number is odd or not
  const isOdd = (num) => {
    if (num % 2 === 0) {
      return false;
    } else if (num % 2 === 1) {
      return true;
    }
  };

  return (
    <Root reverse={reverse} isOdd={isOdd(id)} floorplan={floorplan}>
      {multi ? (
        <ImageContent
          isOdd={isOdd(id)}
          imageArray={data.image}
          images={images}
          floorplan={floorplan}
          darkButtons={darkButtons}
          className={className}
        />
      ) : (
        <ImageContent
          isOdd={isOdd(id)}
          image={img}
          floorplan={floorplan}
          className={className}
        />
      )}

      <TextContent
        isOdd={isOdd(id)}
        floorplan={floorplan}
        className={className}
      >
        <div>
          {data.title && <Title>{splitLine(data.title)}</Title>}
          {data.paragraph && (
            <Paragraph button={data.button} className={className}>
              {splitLine(data.paragraph)}
            </Paragraph>
          )}
          {data.button && !link && (
            <StyledButton
              floorplan={floorplan}
              text
              className={className}
              href={buttonLink}
            >
              {data.button.text}
            </StyledButton>
          )}
          {data.button && link && (
            <StyledButton
              floorplan={floorplan}
              text
              className={className}
              to={getLocaleURL() + data.button.target}
            >
              {data.button.text}
            </StyledButton>
          )}
        </div>
      </TextContent>
    </Root>
  );
};

Content.propTypes = {
  reverse: PropTypes.bool,
  multi: PropTypes.bool,
  darkButtons: PropTypes.bool,
  id: PropTypes.number,
  data: PropTypes.object,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  ${vw('margin-bottom', 70, 70, 260)}
  ${vw('row-gap', 0, 0, 0)}
  ${vw('padding-left', 0, 40, 0)}
  ${vw('padding-right', 0, 40, 0)}
  @media ${media.tablet} {
    align-items: center;
    flex-direction: ${(props) =>
      props.floorplan && !props.isOdd
        ? 'column'
        : props.floorplan && props.isOdd
        ? 'column'
        : !props.floorplan && !props.isOdd
        ? 'row-reverse'
        : !props.floorplan && props.isOdd
        ? 'row'
        : null};
    justify-content: ${(props) =>
      props.floorplan ? 'flex-start' : 'space-between'};
  }

  @media ${media.desktop} {
    align-items: center;
    flex-direction: ${(props) => (!props.isOdd ? 'row-reverse' : 'row')};
  }
`;

const TextContent = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.white};
  ${(props) =>
    props.floorplan ? vw('margin-top', 36, 40, 0) : vw('margin-top', 36, 0)}
  ${(props) =>
    props.floorplan
      ? vw('width', 'auto', 512, 'auto')
      : vw('width', 'auto', 245, 'auto')}
  ${vw('margin-right', 20, 0, 150)}
  ${vw('margin-left', 20, 0, 150)}
  div {
    justify-self: center;
    display: flex;
    flex-direction: column;
    ${vw('column-gap', 0, 0, 0)}
    ${vw('row-gap', 0, 0, 40)}
  }

  @media ${media.tablet} {
    ${(props) => props.floorplan && 'text-align: center'}
  }
  @media ${media.desktop} {
    ${(props) => props.floorplan && 'text-align: left'}
  }
`;

const Title = styled.h1`
  font-weight: 300;
  ${vw('font-size', 30, 30, 42)}
  ${vw('line-height', 36, 36, 50.4)}
  ${vw('letter-spacing', 2, 2, 2)}
  text-align:left;
`;

const Paragraph = styled.p`
  font-weight: 400;
  ${vw('font-size', 14, 14, 16)}
  ${vw('line-height', 20, 20, 24)}
  ${vw('letter-spacing', 0.32, 0.32, 0.32)}
  ${vw('margin-top', 20, 30, 0)}
  ${(props) => props.button && vw('margin-bottom', 20, 30, 0)}
  text-align:left;
`;

const StyledButton = styled(Button)`
  align-self: flex-start; // prevents button from going full width

  @media ${media.tablet} {
    align-self: ${(props) => props.floorplan && 'center'};
  }
  @media ${media.desktop} {
    align-self: ${(props) => props.floorplan && 'flex-start'};
  }
`;

export default Content;
