import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useDispatch } from 'react-redux';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';
import { setQueryVariable } from 'src/redux/tracking';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Loading from 'components/Loading';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    prerenderStyles();
  }, []);

  useEffect(() => {
    const ad_tag_id = getQueryVariable('ad_tag_id');
    const utm_source = getQueryVariable('utm_source');
    const uMedium = getQueryVariable('utm_medium');
    const uCampaign = getQueryVariable('utm_campaign');
    const uContent = getQueryVariable('utm_content');

    dispatch(setQueryVariable('ad_tag_id', ad_tag_id));
    dispatch(setQueryVariable('utm_source', utm_source));
    dispatch(setQueryVariable('utm_medium', uMedium));
    dispatch(setQueryVariable('utm_campaign', uCampaign));
    dispatch(setQueryVariable('utm_content', uContent));
  }, [dispatch]);

  const generateRoutes = () => {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  };

  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    console.log('Query variable %s not found', variable);
  };

  return (
    <Route
      render={({ location }) => (
        <Root>
          <ParallaxProvider>
            <Loading />
            <Header />
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={500} classNames="fade">
                <Switch location={location}>{generateRoutes()}</Switch>
              </CSSTransition>
            </TransitionGroup>
            <Footer />
          </ParallaxProvider>
        </Root>
      )}
    />
  );
};

const Root = styled.div`
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
