import appConfig from 'src/config/app.conf';
import React from 'react';

const { stage, stageHeight } = appConfig;

export default (property, wMobile, wTablet, wDesktop) => {
  if (typeof wTablet === 'undefined') {
    wTablet = wMobile;
  }

  if (typeof wDesktop === 'undefined') {
    wDesktop = wTablet;
  }

  return `${property}: ${
    typeof wMobile === 'string' ? wMobile : vwMobile(wMobile)
  };
    @media (min-width: ${appConfig.mediaQuery.tablet}px) {
      ${property}: ${typeof wTablet === 'string' ? wTablet : vwTablet(wTablet)};
    }
    @media (min-width: ${appConfig.mediaQuery.desktop}px) {
      ${property}: ${
    typeof wDesktop === 'string' ? wDesktop : vwDesktop(wDesktop)
  };
    }
  `;
};

export const vwMobile = (width) => {
  return `${(width / stage.mobile) * 100}vw`;
};

export const vwTablet = (width) => {
  return `${(width / stage.tablet) * 100}vw`;
};

export const vwDesktop = (width) => {
  return `${(width / stage.desktop) * 100}vw`;
};

export const splitLine = (string) => {
  const splitted = string.split('\n');
  return (
    <>
      {splitted.map((item, index) => (
        //
        <React.Fragment key={index}>
          {item}
          {splitted.indexOf(item) < splitted.length ? <br /> : null}
        </React.Fragment>
      ))}
    </>
  );
};

export const vh = (property, hMobile, hTablet, hDesktop) => {
  if (typeof hTablet === 'undefined') {
    hTablet = hMobile;
  }

  if (typeof hDesktop === 'undefined') {
    hDesktop = hTablet;
  }

  return `${property}: ${vhMobile(hMobile)};
    @media (min-width: ${appConfig.mediaQuery.tablet}px) {
      ${property}: ${vhTablet(hTablet)};
    }
    @media (min-width: ${appConfig.mediaQuery.desktop}px) {
      ${property}: ${vhDesktop(hDesktop)};
    }
  `;
};

export const vhMobile = (height) => {
  return `${(height / stageHeight.mobile) * 100}vh`;
};

export const vhTablet = (height) => {
  return `${(height / stageHeight.tablet) * 100}vh`;
};

export const vhDesktop = (height) => {
  return `${(height / stageHeight.desktop) * 100}vh`;
};
