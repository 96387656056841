import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setHasPageLoaded } from 'src/redux/loader';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Loading = () => {
  const { hasPageLoad } = useSelector((state) => state.loader);
  const [hasLoad, setHasLoad] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    !window.__PRERENDERING && setHasLoad(true);
    !window.__PRERENDERING &&
      setTimeout(() => {
        dispatch(setHasPageLoaded(true));
        document.body.style.overflow = '';
      }, 2000);
  }, [dispatch]);

  return (
    <Root hasPageLoad={hasPageLoad} hasLoad={hasLoad}>
      <Container>
        <div>
          <img
            src={require('src/assets/images/home/the-art-of-innovation.svg')}
            alt="The art of innovation"
          />
        </div>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  background-image: url(${require('src/assets/images/background.png')});
  background-size: cover;
  background-position: center;
  transition: 0.4s ease;
  opacity: ${(props) => (props.hasPageLoad ? 0 : 1)};
  pointer-events: ${(props) => (props.hasPageLoad ? 'none' : 'all')};
  /* ${vw('padding-top', 100, 100, 115)} */
  img {
    display: block;
    transition: 0.4s ease;
    transition-delay: 0.5s;
    transform-origin: 50% 50%;
    opacity: ${(props) => (props.hasLoad ? 1 : 0)};
    transform: ${(props) => (props.hasLoad ? 'scale(1)' : 'scale(0.95)')};
    ${vw('width', 280, 500, 681)}
    ${vw('margin-bottom', 0, 0, 40)}
  }
`;

const Container = styled.div`
  height: 80vh;
  width: 100%;
  ${vw('margin-top', 120, 140, 160)}
  @media ${media.tablet} {
    height: 72vh;
  }
  @media ${media.desktop} {
    height: 80vh;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    @media ${media.tablet} {
      height: 100%;
    }
    @media ${media.desktop} {
      height: 76vh;
    }
  }
`;

export default Loading;
