import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { children, content, text, other, to, handleClick, className, href } =
    props;

  const handleType = () => {
    if (to) {
      return Link;
    } else if (href) {
      return 'a';
    } else {
      return 'button';
    }
  };

  return (
    <Root
      other={other}
      content={content}
      text={text}
      to={to}
      as={handleType()}
      target={href ? '_blank' : ''}
      onClick={handleClick ? handleClick : (f) => f}
      className={className}
      {...props}
    >
      {children}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string,
};

const Root = styled.button`
  white-space: normal;
  text-align: center;
  text-transform: uppercase;
  ${vw('border-radius', 6, 6, 6)}
  font-weight: 500;
  ${vw('font-size', 14, 14, 14)}
  ${vw('letter-spacing', 3, 3, 3)}
  ${vw('line-height', 17.09, 17.09, 17.09)}

  &.tc, &.sc {
    ${vw('font-size', 13, 14, 16)}
  }
  ${(props) =>
    props.other
      ? vw('padding-right', 36, 48, 48)
      : props.text
      ? vw('padding-right', 37, 37, 37)
      : null}
  ${(props) =>
    props.other
      ? vw('padding-left', 36, 48, 48)
      : props.text
      ? vw('padding-left', 37, 37, 37)
      : null}
  ${(props) =>
    props.other || props.text ? vw('padding-top', 18, 18, 18) : null}
  ${(props) =>
    props.other || props.text ? vw('padding-bottom', 16, 16, 16) : null}

  ${(props) =>
    props.other || props.text ? vw('margin-top', 20, 20, 20) : null}
  ${(props) =>
    props.other || props.text ? vw('margin-bottom', 16, 16, 16) : null}

  border: 1px solid ${(props) =>
    props.other || props.text
      ? props.theme.color.purpleLight
      : props.theme.color.white};
  color: ${(props) =>
    props.other || props.text
      ? props.theme.color.purpleLight
      : props.theme.color.white};

  @media ${media.tablet} {
    text-align: unset;
  }

  @media ${media.desktop} {
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      background-color: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.purpleDark};
    }
  }
`;

export default Button;
